@import '../../../../../styles/colors.scss';

.update-settings-error {
  position: absolute;
  bottom: -15px;
  right: 0;
  color: $Brand-4;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
}
