@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

.navbar-container {
  .action-buttons {
    width: 100%;

    button {
      border-radius: 15px;
      @include displayFlex(flex-start, center, column);
      row-gap: 5px;

      .icon-title {
        display: inline-block;
        @include fontCaption();
      }

      svg.navbar-icon > * {
        transition: all 0.3s;
      }

      &.inActive {
        svg.navbar-icon {
          & > * {
            fill: transparent;
          }

          .active-fill {
            display: none;
          }

          line {
            stroke: transparent;
          }
        }
      }
    }
  }

  button,
  .user-avatar {
    display: block;
    border: none;
    background-color: transparent;
    padding: 0;
  }
}

@include mobileView {
  .navbar-container {
    width: 100%;
    height: $mobileNavbarHeight;
    @include displayFlex(center, flex-start);
    padding: 4px 19% 30px 19%;
    text-align: center;
    position: fixed;
    bottom: var(--offset-h, 0);
    background-color: $white;
    z-index: $mobileNavbarZIndex;

    .action-buttons {
      @include displayFlex(space-between, flex-start);

      button {
        .icon-title {
          color: $appDarkColor;
        }

        &.inActive {
          svg.navbar-icon > * {
            stroke: $appDarkColor;
          }
        }
      }
    }

    .user-avatar {
      display: none;
    }
  }

  body:has(.product-tour-container) {
    .navbar-container {
      pointer-events: none;
    }
  }
}

@include desktopView {
  $inActiveSvgStrokeColor: $white;

  .navbar-container {
    width: $desktopNavbarWidth;
    height: 100%;
    @include displayFlex(space-between, center, column);
    padding-top: 44px;
    padding-bottom: 50px;
    position: relative;
    background-color: transparent;
    z-index: $settingsOverlayZIndex;

    .action-buttons {
      width: 100%;
      @include displayFlex(flex-start, center, column);
      
      .navbar-max-title{
        @include fontLargeTitle;
        font-size: 16px;
        color: $white;
        margin-bottom: 44px;
      }

      button {
        margin-bottom: 40px;
        row-gap: 5px;

        .icon-title {
          color: $inActiveSvgStrokeColor;
        }

        &.inActive {
          svg.navbar-icon > * {
            stroke: $inActiveSvgStrokeColor;
            fill: transparent;
          }
        }
      }
    }

    .user-avatar .navbar-avatar {
      border-width: 2px;
    }
  }
}
