@import './CalendarDay.module.scss';
@import '../../../../styles/sharedStyle.index.scss';

.calendar-event {
  cursor: pointer;
  user-select: none;
  /* Prevents text selection while dragging an element in mobile */

  .calendar-event-inner-container {
    height: calc(100% - 1px);
    border-radius: 8px;
    padding: $calendarEventPaddingTop $calendarEventPaddingRight $calendarEventPaddingBottom $calendarEventPaddingLeft;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: $planScheduledItemBackground;
    color: $appDarkColor;

    .event-text-container {
      @include displayFlex(flex-start, flex-start, column);
      width: 95%;

      .title-container {
        width: 100%;
      }

      .calendar-event-addition-text {
        @include textTypography(10px, 400);
        white-space: nowrap;
        flex: 1;
      }
    }

    .calendar-event-title {
      $eventTitleIconWrapperSize: 20px;
      width: 100%;
      @include fontFootnote;
      @include displayFlex(flex-start, center);
      column-gap: 11px;
      height: 24px;
      position: relative;

      &.completed-task {
        text-decoration: line-through !important;
      }

      &--event {
        padding-left: calc($eventTitleIconWrapperSize + 7px);
      }

      .task-circle-icon * {
        stroke: var(--calendar-event-first-label-color, $calendarFirstTaskTagDefaultColor);
      }

      .event-icon-circle-background {
        @include displayFlex(center, center);
        position: absolute;
        width: $eventTitleIconWrapperSize;
        height: $eventTitleIconWrapperSize;
        border-radius: 50%;
        background-color: var(--calendar-event-first-label-color, $planScheduledItemBackground);
        left: 0;

        .event-icon--white * {
          stroke: $white;
        }
      }
    }

    .calendar-event-addition-info-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 11px;
    }

    &--30,
    &--over-30 {

      .calendar-event-title .title-text,
      .calendar-event-addition-text {
        width: 90%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--30 {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding-top: 0;
      padding-bottom: 0;
      border: none;

      .event-text-container {
        margin-right: 5px;
      }

      .calendar-event-title {
        margin-bottom: 2px;
      }
    }

    &--over-30 {
      .calendar-event-addition-info-container {
        width: 100%;
      }
    }
  }

  &.resizing {
    cursor: ns-resize;
    /* Change cursor during resizing */
    z-index: $calendarEventWhileResizingZIndex;

    &.event-or-task .calendar-event-inner-container {
      align-items: flex-start;
      padding: $calendarEventPaddingTop $calendarEventPaddingRight $calendarEventPaddingBottom $calendarEventPaddingLeft !important;
    }
  }

  &--work-block {
    .calendar-event-inner-container {
      .event-text-container {
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }
    }

    .draggable-item-is-over {
      border: 2px solid $white !important;
    }
  }

  &.calendar-event--15 {
    margin-bottom: 0;

    .calendar-event-title,
    .calendar-event-addition-text {
      font-size: 10px;
    }
  }
}