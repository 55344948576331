@import '../../../../../../../../styles/sharedStyle.index.scss';

@include mobileView {
  .delete-task-modal {
    width: calc(100vw - 40px) !important;
    position: fixed !important;
    top: calc($mobileHeaderHeight + 10px) !important;
    bottom: unset !important;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px !important;
    border: 1px solid $dark;
    background: $white;
    box-shadow: 0px 2px 0px 0px $dark;
  }
}
