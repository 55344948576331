@import '../../../../../../styles/sharedStyle.index.scss';

$taskDetailsTextareaHeightOfOneLine: 24px;

.details-dialog-animation-container {
  z-index: $TaskFormOverlayZIndex;

  &.slideOutDown {
    animation: slideOutDownAnimation 0.5s forwards;
    -webkit-animation: slideOutDownAnimation 0.5s forwards;
  }
}

.add-edit-task-container,
.work-block-dialog-container {
  @include position(absolute, 0, 0, 0, 0);
  z-index: $TaskFormOverlayZIndex;
  background-color: $white;
  text-align: start;

  &--subtask {
    .add-edit-task-form{
      justify-content: flex-start;
    }
    .parent-task-name-and-tag-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: auto;

      .parent-task-name-header {
        text-align: right;
        @include fontBody;
        text-decoration: underline;
        color: $purple-900;
      }
    }
  }

  &--header {
    @include displayFlex(space-between, flex-start);
    width: 100%;
    position: absolute;
    top: 20px;
    left: 0px;
    padding: 0 20px;

    &--title {
      margin-left: 20px;
      @include fontBody;
      color: $purple-600;
    }

    button.close-modal {
      @include transparencyBtn;
      .back-icon *{
        stroke-width: 2px
      }
    }
  }

  button[type='submit'].save-button {
    @include fontBody;
    width: 80px;
    height: 30px;
    padding-bottom: 9px;
  }

  form.add-edit-task-form,
  .work-block-form {
    @include displayFlex(space-between, stretch, column);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @include fontBody;

    .task-detail-icon {
      margin-right: 8px;

      &--active * {
        stroke: $appDarkColor;
      }
      &--right {
        margin-left: 8px;
        margin-right: 0px;
      }
    }

    .task-textarea.app-rich-text, 
    .edit-notes-modal--text-box, 
    .task-input {
      width: 100%;
      resize: none;
      border: none;
      background-color: transparent;
      @include fontBody;
      line-height: 24px;
      padding: 0;
      color: $taskDetailsTextColor;

      &::placeholder,
      &.placeholder,
      [data-slate-placeholder='true'] {
        color: $taskDetailsPlaceholderColor;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
    .task-details-note{
      @include displayFlex(flex-start, flex-start);
      .task-detail-icon{
        margin-top: -2px;
      }
    }
    .task-notes {
      max-height: calc($taskDetailsTextareaHeightOfOneLine * 2);
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;

      &:focus {
        max-height: max-content;
        display: block;
      }
    }

    .default-value-notes {
      white-space: pre-line;
    }

    .create-or-edit-sub-title {
      color: $purple-600;
      margin-bottom: 12px;
      font-weight: 400;
      @include fontFootnote;
    }

    button.delete-task-item {
      @include transparencyBtn;
      color: $state-error;
      width: fit-content;
      margin-top: 40px;
    }
  }

  // shared className for all the details sections
  .task-detail-section,
  .work-block-detail-section {
    border-bottom: 1px solid $Neutral-1;
    padding-bottom: 16px;
    margin-bottom: 24px;

    &--description {
      @include displayFlex(flex-start, flex-start);
    }
  }
}

@include mobileView {
  .details-dialog-animation-container {
    background-color: $Neutral-4;
  }

  .add-edit-task-container,
  .subtask-details-modal-container {
    border-top: 1px solid $Neutral-3;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 4px;
  }

  .add-edit-task-container,
  .work-block-dialog-container {
    padding-top: 50px;
    height: 100%;

    &:has(.edit-notes-modal--container),
    &:has(.subtask-details-modal-container) {
      overflow: hidden;
    }

    form.add-edit-task-form,
    .work-block-form,
    .work-block-details-view {
      padding: 34px 20px 48px 20px;

      .task-description {
        &:focus {
          font-size: 16px;
        }
      }
    }
  }
}

@include desktopView {
  $saveBtnHeight: 40px;

  .add-edit-task-container,
  .work-block-dialog-container {
    padding-right: $scrollbarMarginRight;
    padding-top: calc($saveBtnHeight + 20px);

    &--header {
      padding-right: calc($createOrEditTaskFormSpaceRightBetweenScrollerToTheContent + $scrollbarMarginRight );
      padding-left: 30px;
    }

    form.add-edit-task-form,
    .work-block-form,
    .work-block-details-view {
      padding: 40px $createOrEditTaskFormSpaceRightBetweenScrollerToTheContent 26px 36px;
      @include designedScrollbar(12px);
    }
  }
}

@keyframes slideOutDownAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}