@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixin.scss';

.single-task {
  padding: 15px 17px;
  @include displayFlex(flex-start, flex-start);
  width: 100%;

  > section {
    width: 50%;
    color: $dark;

    &:first-child {
      padding-right: 30px;
    }

    .single-task-title {
      @include textTypography(16px, 400);
      margin-bottom: 16px;
    }

    .single-task-description {
      max-width: 90%;
      font-size: 11px;
      font-weight: 300;
      display: none;
    }

    .due-date,
    .reminder,
    .working {
      @include textTypography(10px, 400, 1px);
      color: $Neutral-3;

      img {
        height: 9px;
        width: 9px;
        margin-right: 4px;
      }

      section {
        @include textTypography(11px, 400, normal, 14px);
        letter-spacing: normal;
      }
    }

    .due-date .flex {
      margin-top: 9px;
      margin-bottom: 14px;
      @include displayFlex(flex-start, flex-start);

      > section {
        border-radius: 60px;
        background-color: $Neutral-1;
        width: 60px;
        height: 22px;
        @include fontWeight(600);
        @include fontSize(10px);
        color: $dark;
        @include displayFlex(center, center);

        &:first-child {
          margin-right: 7px;
        }
      }
    }

    .reminder .date-time {
      margin-top: 6px;
    }

    .working {
      margin-bottom: 15px;

      .working-time {
        margin-top: 8px;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .single-task > section {
    .single-task-title {
      @include fontSize(16px);
    }

    .single-task-description {
      @include fontSize(11px);
    }

    &:first-child {
      width: 33%;
    }

    &.flex {
      width: 100%;
      @include displayFlex(space-between, flex-start);

      > div {
        width: 50%;
      }
    }
  }
}
