@import '../../../../../styles/colors.scss';
@import '../../../../../styles/typography.scss';

.important-links {
  margin-bottom: 40px;
  a {
    display: block;
    margin-bottom: 14px;
    @include fontFootnote;
    line-height: 18px;
    color: $appDarkColor;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}
