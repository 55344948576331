@import '../../../../styles/mixin.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/layout.scss';

// shared mobile and desktop
.feedback-modal-content-container {
  text-align: center;
  @include displayFlex(space-between, flex-start, column);
  overflow-y: auto;

  > section {
    width: 100%;
  }

  .feedback-modal-para {
    display: block;
    @include fontStyle();
    @include fontWeight(400);
    margin: 0 auto;
  }

  .feedback-modal-link {
    @include textTypography(16px, 400, 0.16px);
    text-decoration-line: underline;
    text-underline-offset: 3px;
  }

  .bottom-container {
    * {
      @include fontSize(12px);
    }
  }
}

// mobile
@include mobileView {
  .feedback-modal-content-container {
    // top right-left bottom
    padding: 6vh 50px 4vh;

    .title {
      margin-bottom: 3vh;
    }

    img {
      margin-bottom: 2.5vh;
      width: 65px;
      height: 76px;
    }

    .feedback-modal-para {
      @include fontSize(14px);
      @include lineHeight(18px);
      margin-bottom: 1vh;
      width: 236px;
    }

    .bottom-container {
      margin-top: 5vh;
      @include displayFlex(center, center);

      .feedback-modal-discord-link {
        position: absolute;
        bottom: -46px;
      }
    }
  }
}

// desktop
@include desktopView {
  .feedback-modal-content-container {
    padding: 10vh 44px 4vh 44px;
    @include designedScrollbar();

    .title {
      margin-bottom: 9vh;
    }

    img {
      margin-bottom: 3vh;
    }

    .feedback-modal-para {
      width: 400px;
      @include textTypography(16px, normal, 0.16px);
      margin-bottom: 5.5vh;
    }

    .bottom-container {
      margin-top: 14vh;
      @include displayFlex(space-between, center);
    }
  }
}
