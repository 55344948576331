@import '../../../../../styles/sharedStyle.index.scss';

$desktopScrollablePaddingRight: 15px;

.stage-scratchpad-container {
  @include displayFlex(flex-start, flex-start, column);
  text-align: start;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .scrollable-frame-container {
    flex: 1;
    overflow-y: auto;
    position: relative;

    .clear-notification {
      position: absolute;
    }
  }
}

@include mobileView {
  $scrollablePaddingRight: 20px;
  $scrollablePaddingLeft: 20px;
  $scratchpadContainerPaddingTop: 10px;

  .stage-scratchpad-container {
    padding: $scratchpadContainerPaddingTop $scrollablePaddingRight 0 $scrollablePaddingLeft;
    position: relative;

    .scrollable-frame-container {
      width: 100%;
      padding: 20px;
      @include displayFlex(flex-start, flex-start, column);
      position: relative;
      padding: 12px 20px;
      background-color: $white;
      border-radius: 8px 8px 0px 0px;

      .clear-notification {
        bottom: 30px;
        width: calc(100% - $scrollablePaddingRight - $scrollablePaddingLeft);
      }
    }
  }
}

@include desktopView {
  $scrollablePaddingLeft: 20px;

  .stage-scratchpad-container {
    .stage-header {
      @include scratchPadHeaderPadding;

      .stage-header-content {
        text-align: right;
        padding-bottom: 10px;
      }
    }

    .scrollable-frame-container {
      width: calc(100% - $scrollbarMarginRight);
      padding: 23px $desktopScrollablePaddingRight 80px $scrollablePaddingLeft;
      overflow-x: hidden;
      @include designedScrollbar($scrollbarTopDistance, $scrollbarBottomDistance);
      background-color: transparent;

      &:has(.scratchpad-empty-state) {
        width: 100%;
      }

      .clear-notification {
        bottom: 47px;
        width: calc(100% - $desktopScrollablePaddingRight - $scrollablePaddingLeft);
      }
    }
  }
}
