@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

.chat-container .conversation {
  flex: 1 1;
  padding: 20px 20px 20px 20px;
  overflow-y: auto;
  z-index: 999;
  @include displayFlex(normal, flex-start, column);

  // additional visibility changes are controlled by the data-attributes-controls.scss file
  .lottie-message-animation-in-chat {
    display: none;
  }

  &:has(.create-session-error) {
    position: relative;
  }

  &:has(.dislike-feedback-container),
  &:has(.chat-history-container) {
    overflow-y: hidden;
  }

  .messages {
    width: 100%;

    &.form-fields {
      flex: 1;
    }

    &.no-history {
      display: none;
    }

    .message:last-child {
      margin-bottom: 0px;
    }
  }

  .separation {
    @include separationLine($teal-300, 16px, 3px);
    margin: 15px 0;
    border-radius: 20px;
  }

  .lottie-message-animation {
    width: 100px;
    height: 86px;

    path {
      // thinking dots color
      fill: $white-transparency-6;
    }

    &.history-loading-animation {
      margin-left: -10px;
    }
  }

  .message-wrapper:not(:has(.message)) {
    display: none;
  }
}

// desktop style
@include desktopView {
  .chat-container .conversation {
    margin-right: $scrollbarMarginRight;
    overflow-y: auto;
    @include designedScrollbar();
    padding-top: 34px;

    .messages {

      .message.bot .msg-content,
      .message.bot .msg-content:has(.feedback-container) {
        position: relative;
        overflow: hidden;

        .feedback-container {
          display: none;
        }

        // when dislike is open
        &:has(.dislike-feedback-container) {
          position: unset;
          overflow: visible;

          .feedback-container {
            display: flex;
          }
        }
      }

      &.form-fields section.message-wrapper-type-bot:last-of-type {
        .msg-content .feedback-container {
          display: flex;
        }
      }
    }
  }
}