@import '../../../../../../../styles/sharedStyle.index.scss';

.task-work-block-overlay {
  width: 100%;

  .assigned-to-title {
    @include textTypography(10px, 400, 1px);
    color: $grey;
    margin-bottom: 12px;
  }

  .task-work-block-details {
    @include textTypography(16px, 400, 0.16px);
    @include displayFlex(flex-start, center);

    &:first-of-type {
      margin-bottom: 12px;
    }

    svg {
      margin-right: 5px;

      path {
        stroke: $dark;
      }
    }
  }
}
