@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/colors.scss';
@import '../SettingsMenu.scss';

$avatarSize: 103px;

.settings-menu-top-section {
  @include displayFlex(flex-start, center);
  width: 100%;
  margin-bottom: 40px;

  .title {
    margin-left: 34px;
    h1 {
      width: min-content;
    }
    .name-email {
      .email {
        word-break: break-all;
      }
    }
  }

  .settings-avatar {
    width: $avatarSize;
    height: $avatarSize;
    min-width: $avatarSize;
    min-height: $avatarSize;
    background-size: cover;
    align-self: baseline;

    &.default-settings-avatar {
      font-size: 48px;
      line-height: normal;
      letter-spacing: 0.48px;
    }
  }

  .title {
    h1 {
      color: $purple-600;
    }

    .email {
      @include displayFlex(flex-start, center);
      @include fontBody;
      height: 30px;
      letter-spacing: .14px;
      color: $black;
    }

    .sign-out {
      width: 100px;
      height: 40px;
      padding: 0;
      border-radius: 5px;
      margin-top: 5px;
      @include fontBody;
    }
  }
}
