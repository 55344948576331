@import '../../../styles/sharedStyle.index.scss';

.auth-hero-card{
  border-radius: 16px;
  background: $teal-purple-gradient;
  overflow: hidden;
  color: $white;
  margin-bottom: 20px;
  min-height: 250px;
  text-align: center;
  @include displayFlex(center, center, column);

  &:has(.auth-sub-title){
    min-height: 280px;
  }

  .sparks-bg{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-image: url('../../../assets/images/onboarding-card-sparks-bg.png');
    background-repeat: no-repeat;
    background-size: 280px;
    background-position: 50% 0px;
    @include displayFlex(center, center, column);
  }

  .auth-card-title {
    @include fontLargeTitle;
    letter-spacing: -0.32px;
    color: $white;
    height: auto;
    text-align: center;
    white-space: pre-line;
  }

  .auth-card-mascot{
    display: block;
    max-height: 100px;
    margin-bottom: 12px;
  }
  .auth-sub-title{
    max-width: 280px;
    margin-top: 8px;
    @include fontFootnote;
  }
}

@media only screen and (max-width: 768px) {
  .auth-hero-card{
    width: 100%;
    height: 32vh;
    padding: 34px 10px;
  }
}

@media only screen and (min-width: 769px) {
  .auth-hero-card{
    width: 100%;
    height: 40vh;
    .sparks-bg{
      background-position: 50% 33%;
    }
  }
}