@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

.header-container {
  width: 100%;
  background-color: transparent;

  .header-content {
    height: 100%;
    @include displayFlex(space-between, center);
  }

  .separation {
    @include separationLine();
  }
}

body:has(.chat-history-container) {
  header {
    background-color: $Neutral-4;
  }
}

body:has(.stage-scratchpad-container) {
  header .minimize-stage img {
    filter: unset;
  }
}

// mobile
@media only screen and (max-width: 768px) {
  .header-container {
    height: $mobileHeaderHeight;
    padding: 0 20px;

    .header-content {
      .max-desktop {
        display: none;
      }
    }
  }
}

// desktop
@media only screen and (min-width: 769px) {
  .header-container {
    @include headerContainerDesktop;

    .header-content {
      .max-mobile {
        display: none;
      }
    }
  }
}

@include mobileView {
  header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $summariesOverlayZIndex;
  }

  .header-container {
    .header-content {
      .clear-scratchpad {
        margin-top: 2px;
      }

      .header-title {
        @include fontSmallTitle(normal);
        color: $purple-600;
      }
    }
  }

  .header-content {
    .my-plan-navigation {
      display: none;
    }
  }

  body:has(.settings-menu) {
    header {
      background-color: $settingsBackgroundColor;
    }
  }
  body:has(.product-tour-container) {
    header {
      pointer-events: none;
      z-index: 1;
    }
  }
}

@include desktopView {
  .header-container {
    @include headerContainerDesktop;

    .header-content {
      .header-title,
      .plan-view-selection,
      .stage-tasks-dropdown-filter,
      .clear-scratchpad,
      .my-plan-navigation {
        display: none;
      }

      .planner-fab {
        display: none;
      }
    }
  }
}