@import './styles/animations.scss';
@import './styles/mixin.scss';
@import './styles/colors.scss';
@import './styles/antd.overwrites.scss';

.App {
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;
  overflow: hidden;
  margin: auto;
  color: $dark;

  & > *:not(#app-overlay-popover-container) {
    height: 100%;
    width: 100%;
  }

  //button cursor default in disabled
  button:disabled {
    cursor: default;
  }

  // hide the arrows from input[type=number]
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error {
    position: absolute;
    height: 33px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: $Brand-4;
  }

  .text-align-right {
    text-align: right;
  }

  .page-loader-animation {
    width: 227px;
  }

  .d-none {
    display: none !important;
  }

  .visibility-hidden {
    visibility: hidden;
    pointer-events: none;
  }

  .cursor-default {
    cursor: default !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .cursor-resize {
    cursor: ns-resize !important;
  }

  .auth-next-btn, .auth-input {
    width: 100%;
    max-width: 300px;
  }
}

@include mobileView {
  .App {
    height: calc(var(--availableViewportHeight) * 100);

    & > *:not(#app-overlay-popover-container) {
      height: calc(var(--availableViewportHeight) * 100);
    }

    .--desktop {
      display: none !important;
    }
  }
}

@include desktopView {
  .App {
    .--mobile {
      display: none !important;
    }
  }
}