@import '../../../../../../../styles/sharedStyle.index.scss';

.edit-phone-modal {
  background-color: $white;
  overflow: visible;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  padding-top: 30px;

  .close-edit-phone-modal {
    @include transparencyBtn;
    position: absolute;
    top: 3px;
    left: 8px;
    width: 24px;
  }

  .phone-verification-form {
    padding-bottom: 55px;
  }
}

@include mobileView {
  .edit-phone-modal .enter-phone-number-get-otp.settings-enter-phone-number {
    margin: 0 auto;
  }

  @media screen and (max-width: 410px) {
    .edit-phone-modal {
      .enter-phone-number-get-otp.settings-enter-phone-number {
        .phone-form-content {
          .phone-controllers-container {
            .phone-registration-container {
              max-width: 70px !important;
            }
          }
        }
      }
    }
  }
}

@include desktopView {
  .app-overlay-popover-background-container {
    &:has(.edit-phone-overlay) {
      background-color: $black-transparency-04;
    }

    .app-overlay-popover-content-container {
      &.edit-phone-overlay {
        width: 348px;
        height: 628px;
        border-radius: 8px;
        top: 120px !important;
        transform: translateX(-50%) !important;
        overflow: visible;
        overflow-y: hidden;

        .edit-phone-modal {
          width: 100%;
          height: 100%;

          .auth-hero-card {
            height: fit-content;
            padding: 15px 0px;
          }

          .enter-phone-number-get-otp.settings-enter-phone-number {
            .auth-next-btn {
              margin-bottom: 20px !important;
            }
          }

          .phone-verification-form .phone-verification-form-content {
            .data-to-verify {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
