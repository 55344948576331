@import '../../../styles/colors.scss';
@import '../../../styles/layout.scss';
@import '../../../styles/mixin.scss';

// shared mobile and desktop-expand mode
@mixin mobileModeStaging {
  .chat-container {
    .chat {
      .conversation {
        display: none;
      }

      .stage-container {
        width: 100% !important;
        display: flex !important;
        flex: 1;
        overflow-y: auto;
      }
    }
  }
}

// shared mobile and desktop
.chat-container {
  z-index: 1;
  @include displayFlex(center, center);

  &.hide {
    opacity: 0;
  }

  .chat {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &:has(.page-loader-animation) {
      .page-loader-animation {
        flex: 1;
        margin: 0 auto;
      }
    }
  }
}

// mobile style
@include mobileView {
  .chat-container {
    width: 100vw;
    height: 100%;
    background-color: $white;

    .chat-frame {
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      text-align: unset;
      box-shadow: none;
      border: none;
      position: relative;
      display: flex;
      flex-direction: column;

      .mobile-header-container {
        align-items: center;
      }

      .header-container {
        height: $mobileHeaderHeight;

        .separation {
          display: none;
        }
      }
    }

    .chat {
      flex: 1;
      height: 100%;
      margin-top: $mobileHeaderHeight;
      height: calc(var(--availableViewportHeight) * 100 - $mobileHeaderHeight);
      padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight);

      &:has(.dislike-feedback-container) {
        padding-bottom: 0;
      }
    }

    .debug-user-card {
      display: none;
    }
  }

  .display-stage {
    @include mobileModeStaging;
  }
}

// desktop style
@include desktopView {
  .chat-container {
    overflow-y: auto;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible;

    .chat-frame {
      height: 100%;
      width: $desktopChatBoxWidth;
    }

    .chat {
      height: calc(100% - $desktopHeaderHeight);

      .stage-container {
        display: none !important;
      }

      .content-frame-wrapper {
        background-color: transparent;
      }
    }

    .debug-user-card {
      position: absolute;
      border-radius: 8px;
      box-shadow: 0px 2px 0px 1px $Neutral-2;
      background-color: $white;
      color: $dark;
      z-index: 99999;
      top: 10px;
      left: 10px;
      padding: 10px;
      max-width: 250px;
    }
  }
}
