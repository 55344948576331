@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';
@import '../../../styles/layout.scss';
@import './ResizableContainer.animations.scss';

@mixin mobileModeStaging {
  .flex-chat-stage-container > .stage-container {
    display: none;
  }

  &.display-stage {
    .chat-container {
      .chat-frame .header-container .header-content {
        .open-close-history-btn-container, .open-close-menu-btn-container {
          visibility: hidden !important;
          position: absolute;
          left: -9999px;
        }
      }
    }
  }
}

.resizable-container {
  width: 100%;
  height: 100%;
  transition: width 0.6s ease;

  >.flex-chat-stage-container {
    z-index: 9;
  }

  // for background opacity transition
  // @include beforeAfterBackgroundTransition($appDislikeFeedbackBackground, $appSummariesBackground);

  &:has(.chat-history-container) {
    &::after {
      opacity: 1;
    }
  }

  .flex-chat-stage-container {
    display: flex;

    .stage-container {
      display: flex;
    }

    .chat-container,
    .stage-container {
      background: transparent;
      z-index: 9;

      .content-frame-wrapper {
        padding: 0;
        border: none;
      }
    }
  }
}

@include mobileView {
  .resizable-container {
    display: block;
    height: 100%;
    background: var(--appMobileBackground);
    background-size: cover;

    &::before,
    &::after,
    .background-animation {
      display: none;
    }

    .flex-chat-stage-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &:has(.chat-history-container),
    &:has(.dislike-feedback-container) {
      .navbar-container {
        display: none;
      }
    }

    &.display-stage {
      background: unset;
      background-color: $white;
    }

    @include mobileModeStaging;
  }
}

@include desktopView {
  .resizable-container {
    @include displayFlex(space-between, center, row-reverse);
    width: 100vw;
    background: var(--appDesktopBackground);
    background-size: cover;

    .background-animation {
      display: none !important;
    }

    .flex-chat-stage-container {
      padding: $chatAndStageContainerPaddingTopBottom $chatAndStageContainerPaddingRightLeft;
      flex: 1;
      @include displayFlex(center, center);
      height: 100%;
      transition: all 0.6s ease;
      width: calc(100% - $desktopNavbarWidth);

      .chat-container {
        height: 100%;
        border-radius: 16px;
        border: 1px solid $teal-300;
        overflow: hidden;

        .chat-frame {
          width: 100%;
          background-color: transparent;
          box-shadow: none;
        }
      }

      .stage-container {
        height: 100%;
        background-color: transparent;
        justify-content: flex-start;
        width: 160px;
        flex: 1;
        overflow: hidden;
        border-radius: 16px 0px 0px 0px;

        .content-frame-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 16px 0px 0px 0px;
          box-shadow: none;
        }
      }
    }

    &.display-stage {
      .flex-chat-stage-container {
        .chat-container {
          .chat-frame {
            padding-right: $numOfPixelsStageCoverChat;
          }
        }
      }
    }

    &.collapse-from-chat-view {
      .chat-container {
        animation: chatFromChatViewToCollapseAnimation 0.8s forwards;
        -webkit-animation: chatFromChatViewToCollapseAnimation 0.8s forwards;
      }

      .stage-container {
        animation: stageFromChatViewToCollapseAnimation 0.8s forwards;
        -webkit-animation: stageFromChatViewToCollapseAnimation 0.8s forwards;
      }
    }

    // from expend to collapse
    &.collapse-from-expend {
      .chat-container {
        animation: chatFromExpandToCollapseAnimation 0.7s forwards, fadeInAnimation 1.5s forwards;
        -webkit-animation: chatFromExpandToCollapseAnimation 0.7s forwards,
          fadeInAnimation 1.5s forwards;

        .chat-bar-form {
          animation: fadeInAnimation 1.3s forwards;
          -webkit-animation: fadeInAnimation 1.3s forwards;
        }
      }

      .stage-container {
        animation: stageFromExpandToCollapseAnimation 0.4s forwards;
        -webkit-animation: stageFromExpandToCollapseAnimation 0.4s forwards;
      }
    }

    // from collapse to expend
    &.expend-from-collapse {

      // close chat animation
      .chat-container {
        animation: chatFromCollapseToExpandAnimation 0.7s forwards;
        -webkit-animation: chatFromCollapseToExpandAnimation 0.7s forwards;
      }
    }

    // chat view mode from expend
    &.chat-view-from-expend {
      .flex-chat-stage-container {
        position: relative;

        .chat-container,
        .chat-container .chat-bar-form {
          animation: fadeInAnimation 0.8s forwards;
          -webkit-animation: fadeInAnimation 0.8s forwards;
        }

        .chat-container {
          @include chatContainerInChatViewOnlyDesktop;
        }

        .stage-container {
          position: absolute;
          left: $chatAndStageContainerPaddingRightLeft;
          right: $chatAndStageContainerPaddingRightLeft;
          top: $chatAndStageContainerPaddingTopBottom;
          bottom: $chatAndStageContainerPaddingTopBottom;
          box-sizing: border-box;
          width: unset;
          height: unset;
          flex: unset;
          z-index: -1;
          animation: stageFromExpendToChatViewAnimation 1s forwards;
          -webkit-animation: stageFromExpendToChatViewAnimation 1s forwards;
        }
      }
    }

    // first time on chat view after reload the window
    &.chat-view-fade-in {
      .chat-container {
        animation: fadeInAnimation 0.5s forwards;
        -webkit-animation: fadeInAnimation 0.5s forwards;
        @include chatContainerInChatViewOnlyDesktop;
      }

      .stage-container {
        opacity: 0;
        width: 0;
        flex: unset;
      }
    }

    // chat view mode from collapse
    &.chat-view-from-collapse {
      .chat-container {
        animation: chatFromCollapseToChatViewAnimation 0.3s forwards;
        -webkit-animation: chatFromCollapseToChatViewAnimation 0.3s forwards;
      }

      .stage-container {
        animation: stageFromCollapseToChatViewAnimation 0.8s forwards;
        -webkit-animation: stageFromCollapseToChatViewAnimation 0.8s forwards;
      }
    }

    // expend view mode from chat view
    &.expend-from-chat-view {
      .flex-chat-stage-container {
        position: relative;
      }

      .chat-container {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: calc(100% - $chatAndStageContainerPaddingTopBottom * 2);
        width: calc($desktopChatBoxWidth - ($chatAndStageContainerPaddingRightLeft * 2) + 5px);
        animation: stageFromExpendToChatViewAnimation 0.5s forwards;
        -webkit-animation: stageFromExpendToChatViewAnimation 0.5s forwards;
      }

      .stage-container {
        opacity: 0;
        animation: fadeInAnimation 1s forwards;
        -webkit-animation: fadeInAnimation 1s forwards;
        width: 100%;
      }
    }
  }
}