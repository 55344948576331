@import '../../../styles/colors.scss';

.app-select-container,
.app-select-container select {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 39px;
  border-radius: 8px;
  background-color: $white;
  cursor: pointer;

  &--open {
    border-radius: 8px 8px 0px 0px;
  }

  select,
  option {
    text-align: center;
    text-align-last: center;
    color: $dark;
  }

  select:focus {
    outline: none !important;
    border: none !important;
  }
}

.app-select-element {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  appearance: none; // Remove default arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-align: center;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.app-custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background: $Neutral-1;
  border-radius: 4px;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.175);
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0px 0px 8px 8px;
  display: none;

  &--open {
    display: block;
  }
}

.app-custom-option {
  padding: 10px;
  cursor: pointer;
}

.app-select-arrow {
  position: absolute;
  right: 7px;
  top: 14px;
  z-index: 1;
}

.app-custom-option:hover {
  background-color: $Neutral-2;
}

.app-select-container .app-custom-select,
.app-select-container .app-select-element {
  width: 100%;
}

.app-select-element-open::after,
.app-custom-select-open::after {
  border-top: none;
  border-bottom: 5px solid black;
}