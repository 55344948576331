@import '../../../../../styles/sharedStyle.index.scss';
@import './StagePlannerVariables.scss';

.stage-planner-container {
  @include displayFlex(flex-start, flex-start, column);
  text-align: start;
  height: 100%;
  width: 100%;
  background-color: transparent;

  .stage-planner-content-container {
    width: 100%;
  }
  .stage-planner-content-planner-container {
    transition: all 0.3s ease;
  }
  .stage-planner-content-planner-container {
    height: 100%;
    width: 100%;
  }
}

.stage-planner-content-unscheduled-tasks-container {
  transition: all 0.3s ease;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0px;
}

@include mobileView {
  .stage-planner-container {
    padding: 10px 20px 0 20px;
  }
  .stage-planner-content-container {
    height: 100%;
  }
  .stage-planner-content-unscheduled-tasks-container {
    display: none;
  }
}

@include desktopView {
  .stage-planner-container {
    .stage-header {
      width: 100%;
      padding-left: 20px;

      .stage-header-content {
        text-align: left;
        padding-bottom: 10px;
      }
    }
  }
  .stage-planner-content-container {
    @include displayFlex(flex-start, flex-start);
    height: 100%;
    width: 100%;
    padding-right: $stageContentPaddingRightDesktop;
    padding-left: $stageContentPaddingLeftDesktop;
    padding-top: 35px;
  }
  // on expand mode
  .display-stage.stage-expanded .stage-planner-content-container {
    padding-right: $stagePlanContainerPaddingRightLeftOnExpendMode;
    padding-left: $stagePlanContainerPaddingRightLeftOnExpendMode;
  }
  .stage-planner-content-unscheduled-tasks-container {
    height: 100%;
    width: 0;
  }
  body[data-planner-state='unscheduledtasksplacer'] {
    .stage-planner-content-container {
      padding-right: 0;
    }
    .stage-planner-content-planner-container {
      width: calc(100% - $plannerWidthWhenOpen - $spaceBetweenPlannerToPlanContent) !important;
    }
    .stage-planner-content-unscheduled-tasks-container {
      width: $plannerWidthWhenOpen !important;
    }
  }
  .resizable-container.display-stage.stage-expanded.planner .stage-planner-container {
    .stage-planner-content-container {
      height: 100%;
    }
  }
}
