@import '../../../../styles/sharedStyle.index.scss';
@import './ChatFormUserInput.module.scss';

.chat-bar-form {
  width: 100%;
  @include displayFlex(space-between, flex-start);
  flex-wrap: nowrap;
  background-color: $white;
  border-top: 1px solid $purple-600;
  padding: 8px 20px;

  section {
    width: 100%;
    background-color: $Neutral-3;
    @include displayFlex(space-between, flex-start);
    flex-wrap: nowrap;
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;

    &:has(button.cancel-recording){
      align-items: center;
    }

    textarea {
      width: 93%;
      // max 5 lines
      max-height: $chatBarTextAreaMaxHeightMobile;
      border: none;
      background-color: transparent;
      color: $appDarkColor;
      padding: 0px;
      resize: none;
      margin: 1px 0 0 0 !important;
      padding-right: 12px;
      @include fontBody;
      line-height: 24px;
      align-self: center !important;

      &::-webkit-scrollbar {
        width: 0px;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: default;
      }

      &:placeholder-shown {
        height: 22px !important;
      }

      &::placeholder {
        color: $green-100;
      }

      &.recording {
        &::placeholder {
          color: $red-500;
        }
      }
    }

    button.cancel-recording {
      @include transparencyBtn;
      @include fontFootnote();
      color: $appDarkColor;
      padding: 0;
      text-decoration-line: underline;
      text-underline-offset: 2px;
      margin-right: 20px;
    }

    button.chat-form-submit {
      height: auto;
      width: auto;
      border: none;
      background-color: transparent;
      @include displayFlex(center, center);
      padding: 0;
      margin: 0 !important;

      &--disabled {
        opacity: 0.5;
      }

      img.send-button {
        height: 24px;
      }

      img.mic-button {
        height: 24px;
        cursor: pointer;

        &--loading {
          animation: micRotation 500ms infinite;
        }
      }
    }
  }

  .confirm-modal-background {
    background-color: transparent;
  }

  .confirm-modal {
    width: 85%;
    min-height: 160px !important;
    padding-top: 28px;

    .confirm-modal-content .confirm-modal-title {
      @include fontWeight(500);
      text-align: start;
      margin: 0;
    }

    .confirm-modal-footer button {
      @include textTypography(14px, 400, 0.14px);
      color: $Neutral-1;
    }
  }
}

@include mobileView {
  .chat-bar-form {
    height: $mobileChatFormInputHeight;
    width: 100%;
    position: fixed;
    bottom: calc(var(--offset-h, 0) + $mobileNavbarHeight);
    z-index: $summariesOverlayZIndex !important;
    align-items: flex-start;
    border-radius: 16px 16px 0px 0px;

    &:has(.send-button) {
      height: auto;
      min-height: $mobileChatFormFocusedInputHeight;
    }

    section {
      border-bottom: 1px solid $Neutral-1;
      // !important -> to prevent from the browser default style to effect the padding
      padding: 1px 0 8px 0 !important;

      textarea {
        height: 20px;
        min-height: 20px !important;
      }

      button.cancel-recording {
        margin-right: 24px;
      }
    }

    .confirm-modal {
      max-width: 350px;
      top: 15px;
      padding-right: 30px;
      padding-left: 30px;

      .confirm-modal-footer button {
        width: 100px;
      }
    }
  }
}

@include desktopView {
  .chat-bar-form {
    width: calc(100% - 40px);
    margin: 0 auto;
    margin-bottom: $chatBarMarginBottom;
    height: $desktopChatFormInputHeight;
    min-height: $desktopChatFormInputHeight;
    align-items: center;
    border-radius: 8px;
    // gradient border
    @include appGradientBorderForInputElement;

    &:has(.send-button) {
      height: auto;
      min-height: $desktopChatFormInputHeight;
    }

    section {
      height: 100%;

      textarea {
        height: 22px;
        min-height: 22px !important;
      }
    }

    .confirm-modal {
      max-width: 455px;
      top: 20px;
      padding-right: 40px;
      padding-left: 40px;

      .confirm-modal-footer button {
        width: 130px;
      }
    }
  }
}

@keyframes micRotation {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(50deg);
  }

  50% {
    transform: rotateY(150deg);
  }

  75% {
    transform: rotateY(200deg);
  }

  90% {
    transform: rotateY(250deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

body:has(.chat-container .dislike-feedback-container) {
  .chat-bar-form {
    display: none !important;
  }
}