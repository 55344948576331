$white: #ffffff;
$white-2: #f1f1f1;
$white-3: #f7e6e6;
$white-transparency-2: rgba(255, 255, 255, 0.2);
$white-transparency-3: rgba(255, 255, 255, 0.3);
$white-transparency-4: rgba(255, 255, 255, 0.4);
$white-transparency-6: rgba(255, 255, 255, 0.6);
$white-transparency-9: rgba(255, 255, 255, 0.9);
$black: #000000;
$black-transparency-2: rgba(0, 0, 0, 0.2);
$black-transparency-04: rgba(0, 0, 0, 0.4);
$black-transparency-025: rgba(0, 0, 0, 0.25);
$dark: #334c45;
$Brand-1: #a9ecd9;
$Brand-2: #fffdf6;
$Brand-3: #726da8;
$Brand-4: #ff6480;
$Brand-6: #d1f3e9;
$Neutral-1: #f5f5f5;
$Neutral-1-scrollbar-track: rgb(245, 245, 245, 0.4);
$Neutral-2-scrollbar-thumb: rgb(217, 217, 217, 0.6);
$Neutral-2: #d9d9d9;
$Neutral-2-RGBA-03: rgba(217, 217, 217, 0.3);
$Neutral-3: #b3b3b3;
$Neutral-4: #fbfafa;
$Neutral-5: #e4f0ed;
$Neutral-200: #F2F2F2;
$Neutral-300: #C0C0C0;
$Neutral-400: #A0A0A0;
$Neutral-500: #B2B2B2;
$Neutral-600: #606060;
$Neutral-700: #333;
$Max-Neutral-5: #919191;
$Neutral-6: #7b7b7b;
$Yellow: #ffd057;
$grey: #929292;
$selectedRed: #dd526b;
$grey-2: #989898;
$grey-3: #777;
$light-grey: #e8e9ea;
$Brand-3-light: #c4c1e4;
$purple-3: #7748ff;
$purple-2: #5865f2;
$purple-4: #a9a9ff;
$purple-5: #4600da;
$purple-100: #FCFBFF;
$purple-200: #F3F1FF;
$purple-300: #E3DDFF;
$purple-400: #CBC0FF;
$purple-500: #A995FF;
$purple-600: #7748FF;
$purple-700: #623CD3;
$purple-800: #462A96;
$purple-900: #2E1A67;
$orange: #ffd057;
$orange-2: #f79964;
$orange-3: #ff971d;
$orange-4: #ebbb41;
$yellow-2: #ffe296;
$blue: #0a12e5;
$blue-2: #6e8eff;
$blue-3: #010449;
$blue-4: #2f9bff;
$blue-5: #455cd4;
$blue-6: #277dff;
$turquoise: #00acf6;
$turquoise-2: #7dceb7;
$turquoise-3: #64c1cd;
$red-2: #f1543f;
$red-500: #D7516A;
$sky: #277DFE;
$pink-transparency: rgba(255, 220, 227, 0.3);
$debug-function-msg-color: rgba(125, 195, 241, 0.63);
$calendar-column-border: rgba(217, 217, 217, 0.5);
$teal-purple-gradient: linear-gradient(180deg, $purple-3 20%, $sky 50%, #69DFBD 100%);
$state-error: #FF607D;
$teal-300: #C1FFE7;
$teal-400: #98FFD9;
$teal-500: #2FFFC4;
$teal-600: #2CF0B9;
$green-100: #C6C8C7;
$neutral-400: $Neutral-2;
$appDarkColor: $purple-900;
$firstTaskTagDefaultColor: $purple-400;
$calendarFirstTaskTagDefaultColor: $purple-300;
$taskDetailsPlaceholderColor: $Neutral-500;
$taskDetailsTextColor: $appDarkColor;
$planScheduledItemBackground: $purple-200;
$transparent: transparent;

// App BG gradient (use BG4 with background image)
$desktop-BG1: linear-gradient(2deg, $Brand-1 -37.37%, $turquoise 44.32%, $blue 134.07%);
$mobile-BG1: linear-gradient(2deg, $Brand-1 -37.37%, $turquoise 49.22%, $blue 134.07%);

$desktop-BG2: linear-gradient(359.11deg,
    $orange-4 -67.64%,
    $turquoise-3 17.61%,
    $blue-5 77.28%,
    $purple-5 157.36%);
$mobile-BG2: linear-gradient(359.11deg,
    $orange-4 -67.64%,
    $turquoise-3 17.61%,
    $blue-5 77.28%,
    $purple-5 157.36%);

$desktop-BG3: radial-gradient(162.5% 161.43% at 44.65% 155.11%,
    $orange 0%,
    $orange-3 39.44%,
    $red-2 55.05%,
    $blue 100%);
$mobile-BG3: radial-gradient(148.82% 149.7% at 50% 160.12%,
    $orange 0%,
    $orange-3 37.22%,
    $red-2 64.22%,
    $blue 100%);

// app BG4 for the night with sparks
// $desktop-BG4: url("../assets/images/app-spark-bg-desk.png"), url("../assets/images/app-BG4-desktop.png");
// $mobile-BG4: url("../assets/images/app-spark-bg-mobile.png"), url("../assets/images/app-BG4-mobile.png");
$desktop-BG4: radial-gradient(148.82% 149.7% at 50% 160.12%, $purple-3 0%, $blue-3 100%);
$mobile-BG4: radial-gradient(148.82% 149.7% at 50% 160.12%, $purple-3 0%, $blue-3 100%);

$Jewel-Gradient-1: linear-gradient(99deg, #ccceff -26.01%, #ff9e67 129.4%);
$Jewel-Gradient-2: linear-gradient(92deg, #7748ff -6.6%, rgba(255, 255, 255, 0) 96.37%);

$desktop-onboarding-BG: url('../assets/images/onboarding-spark-bg-desk.svg'),
  url('../assets/images/onboarding-bg-desktop.svg');
$mobile-onboarding-BG: url('../assets/images/onboarding-spark-bg-mobile.svg'),
  url('../assets/images/onboarding-bg-mobile.svg');
