@import '../../../../../styles/sharedStyle.index.scss';
@import './WorkBlockTasksList.module.scss';

.work-block-calendar-details {
  @include displayFlex(flex-start, flex-start);
  flex-wrap: wrap;
  flex: 1;
  margin-top: $workBlockTaskListMarginTop;
  width: 100%;
  overflow: hidden;

  .work-block-tasks-list {
    @include displayFlex(flex-start, flex-start, column);
    flex-wrap: wrap;
    row-gap: $workBlockTaskListGap;
    width: 100%;

    .work-block-task-item {
      width: 100%;
      height: $workBlockTaskItemHeight;
      padding: 0px 8px;
      @include displayFlex(flex-start, center);
      user-select: none; /* Prevents text selection while dragging a workBlock task in mobile */
      background-color: $planScheduledItemBackground;

      .calendar-work-block-task-name {
        @include fontFootnote();
        display: block;
        color: $appDarkColor;
        width: 100%;
        @include displayFlex(flex-start, center);
        column-gap: 4px;

        .work-block-task-title-text{
          width: 90%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .work-block-task-icon{
          height: 10px;
          width: 10px;

          * {
            stroke: var(--task-circle-icon-color, $calendarFirstTaskTagDefaultColor);
          }
        }
      }
    }
  }

  .recurrence-and-more-btn-container {
    align-self: flex-end;
    width: 100%;
    @include displayFlex(space-between, center, row, nowrap);

    &:not(:has(.work-block-more-tasks-btn)) {
      justify-content: flex-end;
    }

    .work-block-more-tasks-btn {
      @include transparencyBtn;
      @include textTypography(10px, 400);
      white-space: nowrap;
      color: $appDarkColor;
    }
  }
}
