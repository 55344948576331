@import '../../../../../../../../styles/sharedStyle.index.scss';

.custom-recurrence {
  .custom-recurrence-sub-title {
    @include textTypography(16px, 500, 0.16px);
    margin-bottom: 5px;
  }
}

.recurrence-select-week-days {
  @include displayFlex(center, center);
  gap: 14px;

  button.week-day {
    display: block;
    @include textTypography(16px, 500);
    width: 38px;
    height: 73px;
    border-radius: 50px;
    background: $Neutral-1;
    border: none;
    transition: all 0.5s;

    &--selected {
      background: $Brand-1;
    }
  }
}

@include mobileView {
  .recurrence-select-week-days {
    margin-top: 50px;
  }
}

@include desktopView {
  .recurrence-select-week-days {
    margin-top: 38px;
  }
}
