@import '../../../styles/colors.scss';
@import '../../../styles/layout.scss';
@import '../../../styles/animations.scss';
@import '../../../styles/mixin.scss';

.app-overlay-popover-background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $appOverlayComponentZIndex;
  pointer-events: all; // Disable interactions by default

  &.allow-interaction {
    background: transparent; // Make background transparent to allow interaction
    pointer-events: none; // Enable interactions for the background
  }

  .app-overlay-popover-content-container {
    z-index: 1000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: $white;
    pointer-events: auto; // Enable interactions for the popup content

    // custom style for sub-task-overlay
    &.sub-task-overlay {
      overflow-y: auto;
      border-radius: 7px;
    }
  }

  &:has(.sub-task-overlay),
  &:has(.background-none) {
    animation-duration: 0s;
  }
}

.task-edit-overlay-container:has(.task-edit-overlay-duration-picker-container) {
  justify-content: flex-start;
}

@include mobileView {
  .app-overlay-popover-background-container {
    height: calc(var(--availableViewportHeight) * 100);

    &:not(:has(.full-screen-overlay)) {
      background-color: $popoverOverlayBackground;
    }

    &:has(.background-none) {
      background-color: transparent;
    }

    &:has(.slideOut-mobile) {
      animation: fadeOutAnimation 0.3s forwards;
      -webkit-animation: fadeOutAnimation 0.3s forwards;
    }

    .app-overlay-popover-content-container {
      position: fixed;
      width: 100vw;
      bottom: var(--offset-h, 0);
      border-radius: 15px 15px 0 0;
      border: 1px solid $Neutral-2;
      max-height: 100vh;
      max-height: calc(var(--availableViewportHeight) * 100);
      overflow-y: auto;

      // animation classes
      &.slideIn-full-screen-mobile,
      &.slideIn-half-screen-mobile {
        animation: slideUpInAnimation 0.5s forwards;
        -webkit-animation: slideUpInAnimation 0.5s forwards;
        animation-fill-mode: none !important;
        -webkit-animation-fill-mode: none !important;
      }
      &.full-screen-overlay {
        top: 4px;
      }
      &.slideOut-mobile {
        animation: slideDownOutAnimation 0.5s forwards;
        -webkit-animation: slideDownOutAnimation 0.5s forwards;
      }
    }
  }
}

@include desktopView {
  .app-overlay-popover-background-container {
    .app-overlay-popover-content-container {
      position: fixed;
      left: -99999px;
      border-radius: 16px;
      width: calc($appOverlayCardWidthDesktop * 1px);
      box-shadow: 0px 4px 4px 0px $black-transparency-025;

      &.d-none {
        display: none;
      }

      &.fadeOut {
        animation-duration: 0.3s !important;
        -webkit-animation-duration: 0.3s !important;
      }
    }
  }
}

@keyframes slideUpInAnimation {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideDownOutAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}
