@import '../../../../styles/mixin.scss';
@import '../../../../styles/colors.scss';

.release-notes-container {
  text-align: start;
  @include displayFlex(space-between, flex-start, column);
  max-height: 100%;

  > section {
    width: 100%;
  }

  .release-notes-content {
    overflow-y: auto;
    position: relative;
    white-space: pre-line;

    .subtitle {
      @include fontStyle();
      @include fontWeight(400);
      @include lineHeight();
      color: $black;
    }

    img {
      position: absolute;
      z-index: 9999;
    }

    .separation {
      @include separationLine();
    }
  }

  .bottom-container {
    * {
      @include fontSize(12px);
    }
  }
}

// mobile
@include mobileView {
  .release-notes-container {
    padding: 48px 20px 30px 20px;

    > section {
      &:first-child {
        height: calc(100% - 59px);
      }
    }

    .title {
      text-align: center;
      margin-bottom: 14px;
    }

    .release-notes-content {
      @include textTypography(14px, 400, normal, 18px);
      color: $Neutral-3;
      height: calc(100% - 44px);

      .subtitle {
        @include fontSize(16px);
        @include letterSpacing(0.16px);
        margin-top: 55px;
        margin-bottom: 8px;
      }

      img {
        right: 0;
        top: 13px;
      }

      .separation {
        @include separationLine($Neutral-1);
        margin-bottom: 34px;
      }
    }

    .bottom-container {
      margin-top: 29px;
      @include displayFlex(center, center);

      .release-notes-discord-link {
        position: absolute;
        bottom: -46px;
      }
    }
  }
}

// desktop
@include desktopView {
  .release-notes-container {
    padding: 50px 40px 40px 42px;

    > section {
      &:first-child {
        height: calc(100% - 93px);
      }
    }

    .title {
      margin-bottom: 14px;
    }

    .release-notes-content {
      padding-right: 40px;
      height: calc(100% - 66px);
      padding-bottom: 20px;
      @include textTypography(12px, 400, 0.12px);
      @include designedScrollbar();

      &::-webkit-scrollbar-button:start:decrement {
        height: 8px;
      }

      &::-webkit-scrollbar-button:end:increment {
        height: 0px;
      }

      .subtitle {
        margin-top: 46px;
        @include fontSize(24px);
        @include letterSpacing(0.24px);
        margin-bottom: 20px;
      }

      img {
        right: 49px;
        top: 27px;
      }

      .separation {
        margin-bottom: 50px;
      }
    }

    .bottom-container {
      margin-top: 76px;
      @include displayFlex(space-between, center);

      .release-notes-discord-link,
      .release-notes-discord-link * {
        color: $dark;
      }
    }
  }
}
