@import '../../../../../../styles/sharedStyle.index.scss';

.task-overlay-quick-options-single-option {
  width: auto;
  height: 32px;
  background: none;
  color: inherit;
  border: none;
  padding: 0 12px;
  font: inherit;
  outline: inherit;
  border-radius: 8px;
  background: $Neutral-1;
  padding: 0;
  padding-top: 5px;
  text-align: left;
  @include displayFlex(center, center, column);

  h2 {
    @include fontCaption;
    cursor: pointer;
    width: auto;
    background: $Neutral-1;
    color: $appDarkColor;
  }

  p {
    color: $dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
