@import '../../../../../../styles/sharedStyle.index.scss';

.scratchpad-empty-state {
  @include displayFlex(center, center);
  width: 100%;
  gap: 18px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  &--paragraph {
    @include textTypography(16px, 500);
    color: $appDarkColor;
    white-space: pre-line;
  }
}

@include mobileView {
  .scratchpad-empty-state {
    flex-direction: column-reverse;
    bottom: 21px;

    &--paragraph {
      text-align: center;
    }
  }
}

@include desktopView {
  .scratchpad-empty-state {
    bottom: 30px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 20px;

    &--paragraph {
      &:last-child {
        white-space: unset;
      }
    }
  }
}
