@import '../../../styles/colors.scss';

.discord-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $dark;

  span {
    color: $Brand-3;
  }
}
