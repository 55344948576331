@import '../../../../../../../../styles/sharedStyle.index.scss';

.quick-options-reminder {
  @include displayFlex(flex-start, center);
  flex-wrap: wrap;
  row-gap: 20px;

  .quick-option-reminder-workTime {
    .task-overlay-quick-options-single-option {
      height: 32px;
    }
  }
}

@include mobileView {
  .quick-options-reminder {
    .quick-option-reminder-workTime {
      .task-overlay-quick-options-single-option {
        width: 80px;
        height: 70px;
        margin-right: 12px;

        h2 {
          @include fontSize(14px);
        }
      }
    }

    button.open-custom-register {
      @include fontSize(14px);
    }
  }
}
