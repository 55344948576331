@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixin.scss';

$submitHeight: 30px;

.settings-feedback {
  width: 100%;

  form {
    width: 100%;
    text-align: left;
    @include displayFlex(flex-start, flex-start, column);
    font-size: 14px;
    font-weight: 400;

    label {
      @include fontFootnote;
      display: block;
      margin-bottom: 22px;
      line-height: 18px;
      color: $black;
    }

    .error-relative {
      position: relative;
      height: fit-content;
      width: 100%;
      margin-bottom: 10px;

      textarea {
        width: 100%;
        height: 180px;
        border-radius: 10px;
        background-color: $purple-100;
        line-height: 18px;
        padding: 19px 12px;
        border: transparent;
        resize: none;
        color: $dark;
        letter-spacing: 0.14px;
        font-size: 16px;
        font-weight: 400;

        &::placeholder {
          color: $purple-400;
          letter-spacing: 0.14px;
          @include fontFootnote;
        }

        &:focus {
          outline: none;
        }
      }

      .update-settings-error {
        bottom: 16px;
        left: 10px;
      }
    }

    .email-and-submit-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      column-gap: 10px;

      section {
        @include displayFlex(flex-start, center);
        column-gap: 10px;
      }

      .email-input {
        height: $submitHeight;
        width: 100%;
        border: none;
        border-radius: 10px;
        background-color: $purple-100;
        @include textTypography(16px, 400);
        padding: 0 10px;
        padding-bottom: 1px;

        &::placeholder {
          color: $purple-400;
          @include fontBody;
        }
        &:focus {
          outline: none;
        }
      }

      .switch-email-text {
        @include fontCaption;
        color: $purple-400;
        margin-left: 52px;
        height: 22px;
        display: flex;
        align-items: center;
      }
    }

    button.submit-feedback {
      width: 81px;
      height: $submitHeight;
      padding: 0;
      letter-spacing: 0.12px;
      @include fontFootnote;

      &:disabled {
        opacity: 0.6;
      }
    }

    .email-switch-section {
      width: 100%;
    }
  }
}

@include desktopView {
  .settings-feedback {
    margin-bottom: 24px;

    form {
      label {
        margin-bottom: 28px;
      }

      .error-relative {
        margin-bottom: 20px;

        textarea {
          height: 190px;
          letter-spacing: 0.16px;
          font-size: 16px;
          padding: 19px 21px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .settings-feedback {
    form {
      .email-and-submit-container {
        section.email-switch-section {
          position: relative;

          .switch-email-text {
            position: absolute;
            bottom: -20px;
            left: 0;
            display: block;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
