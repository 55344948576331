@import '../../../../../../styles/sharedStyle.index.scss';

.scratchpad-notification {
  width: 100%;
  height: 40px;
  @include displayFlex(flex-start, center);
  @include textTypography(14px, 400);
  background-color: $Neutral-5;
  border-radius: 15px;
  overflow: hidden;

  &.shrinkOut {
    animation: shrinkOutAnimation 0.5s forwards;
    -webkit-animation: shrinkOutAnimation 0.5s forwards;
  }
}

@include mobileView {
  .scratchpad-notification {
    padding: 0px 16px;
  }
}

@include desktopView {
  .scratchpad-notification {
    padding: 0px 20px;
  }
}

@keyframes shrinkOutAnimation {
  0% {
    height: 40px;
  }

  100% {
    height: 0px;
  }
}
