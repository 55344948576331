@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixin.scss';

.invite-friends-card-container {
  height: auto;
  &--invite {
    .invite-friends-flex {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      .invite-friends-header-and-mascot-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .invite-friends-header {
        @include fontLargeTitle(-0.32px);
        color: $purple-600;
      }
      .invite-friends-text {
        @include fontFootnote;
        line-height: 18px;
        margin: 14px 0;
      }
      .invite-friends-button {
        height: 37px;
        width: 100px;
        letter-spacing: 0.12px;
        @include fontFootnote;
        align-self: flex-end;
      }
    }
  }
}
