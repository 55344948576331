$numOfPixelsStageCoverChat: 20px;
// collapse
@mixin chatContainerInCollapseViewDesktop($marginRight: 0px) {
  width: 40vw;
  min-width: 300px;
  max-width: calc(505px + $numOfPixelsStageCoverChat);
  margin-right: $marginRight;
  border: 0px solid transparent;
  border-radius: 16px 0px 0px 0px;
}

@mixin stageContainerInCollapseViewDesktop {
  width: 160px;
  max-width: unset;
  flex: 1;
  margin-left: calc($numOfPixelsStageCoverChat * -1);
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.1);
}

// chat view only
@mixin chatContainerInChatViewOnlyDesktop {
  width: $desktopChatBoxWidth;
  max-width: unset;
  min-width: unset;
  border-color: $teal-300;
}

@mixin stageContainerInChatViewOnlyDesktop {
  max-width: unset;
  flex: unset;
  width: 0px;
}

// expand
@mixin chatContainerInExpandViewDesktop {
  width: 0;
  max-width: 0;
  min-width: 0;
  margin-right: 0px;
  border: 0px solid transparent;
}

// animations:

// collapse -> expand
@keyframes chatFromCollapseToExpandAnimation {
  from {
    opacity: 1;
    @include chatContainerInCollapseViewDesktop;
  }

  to {
    opacity: 0;
    @include chatContainerInExpandViewDesktop;
  }
}

// expand -> collapse
@keyframes stageFromExpandToCollapseAnimation {
  to {
    @include stageContainerInCollapseViewDesktop;
  }
}

@keyframes chatFromExpandToCollapseAnimation {
  from {
    @include chatContainerInExpandViewDesktop;
  }

  to {
    @include chatContainerInCollapseViewDesktop;
  }
}

// expend -> chat view mode
// stage animation from expend view to chat view only
@keyframes stageFromExpendToChatViewAnimation {
  from {
    opacity: 1;
    display: block;
  }

  to {
    opacity: 0;
    display: none;
  }
}

// collapse -> chat view mode
// stage animation from collapse side by side view to chat view
@keyframes stageFromCollapseToChatViewAnimation {
  from {
    opacity: 1;
    @include stageContainerInCollapseViewDesktop;
    margin-left: 0px;
  }

  to {
    opacity: 0;
    @include stageContainerInChatViewOnlyDesktop;
    margin-left: 0px;
  }
}

// collapse -> chat view mode
// chat container animation from collapse side by side view to chat view only
@keyframes chatFromCollapseToChatViewAnimation {
  from {
    @include chatContainerInCollapseViewDesktop(0);
  }

  to {
    @include chatContainerInChatViewOnlyDesktop;
  }
}

// chat view mode -> collapse
// stage container animation from chat view to collapse side by side view
@keyframes stageFromChatViewToCollapseAnimation {
  from {
    opacity: 0;
    @include stageContainerInChatViewOnlyDesktop;
  }

  to {
    opacity: 1;
    @include stageContainerInCollapseViewDesktop;
  }
}

// chat view mode -> collapse
// chat container animation from chat view to collapse side by side view
@keyframes chatFromChatViewToCollapseAnimation {
  from {
    @include chatContainerInChatViewOnlyDesktop;
  }

  to {
    @include chatContainerInCollapseViewDesktop;
  }
}

// chat view mode -> expend
@keyframes chatFromChatViewToExpandAnimation {
  from {
    @include chatContainerInChatViewOnlyDesktop;
  }

  to {
    @include chatContainerInCollapseViewDesktop;
  }
}
