@import '../../../../../../../../styles/sharedStyle.index.scss';
@import '../singleTaskItemContainer.module.scss';

.task-action-button {
  @include displayFlex(center, center);
  border: none;
  font-family: 'Dosis' !important;
  color: $white;
  @include fontCaption;
  height: 30px;
  
  &--due {
    background: $teal-600;
    color: $purple-800;
  }

  &--schedule {
    background: $purple-600;
  }

  &--delete {
    background: $Brand-4;
  }
}

@include desktopView {
  .task-action-button {
    border-radius: 4px;
    margin-right: 5px;
    padding: 0;
    width: 64px;
  }
}

// mobile view
@mixin taskActionInMobileAndTablets {
  width: calc($singleTaskActionButtonWidth * 1px);
  margin: 0;
  padding: 0;
  padding-bottom: 1px;
  border-radius: 4px;
  border: none;

  &.task-action-button {
    &--delete {
      margin-right: 1px;
    }
  }
}

@include mobileView {
  .task-action-button {
    @include taskActionInMobileAndTablets;
  }
}

@include TabletsAndIPadsView {
  .task-action-button {
    @include taskActionInMobileAndTablets;
  }
}

.task-action-button--mobile-view {
  @include taskActionInMobileAndTablets;
}
