@import '../../../../../styles/colors.scss';

.internal-user-settings {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  }

  h1 {
    color: #333;
    margin-bottom: 15px;
  }

  h3 {
    margin-bottom: 10px;
    color: #555;
  }

  .feature-flags-textarea {
    width: 100%;
    font-family: monospace;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    resize: vertical;
  }

  .save-feature-flags-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: $dark;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }

  .error-message {
    color: #d9534f;
    margin-top: 10px;
    font-weight: bold;
  }
}
