@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.welcome-carousel-container {
  height: 100vh;
  height: calc(var(--availableViewportHeight) * 100);
  width: 100vw;
  border-radius: 8px;
  position: absolute;
  top: 0;
  z-index: 99999;

  .content-frame-wrapper {
    background-color: $white;
    position: relative;
    overflow: unset;
  }

  .next {
    position: absolute;
    bottom: 30px;
    right: 30.5px;
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    border: transparent;
    z-index: 999;

    img {
      width: 24px;
      height: 24px;
    }

    &:focus-visible {
      outline: none;
      border: 1px solid $dark;
    }
  }

  .ant-carousel {
    width: 100%;
    height: 100%;
    border-radius: 8px;

    .welcome-carousel {
      width: 100%;
      height: 100%;
      border-radius: 8px;

      div {
        border-radius: 8px;
        width: 100%;
        height: 100%;

        .carousel-element {
          text-align: center;

          .title {
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.01em;
            margin-bottom: convertPxToVhVw(40, 'vh', 758);
            color: $dark;
            white-space: pre-line;
          }

          .image-container {
            margin: 0 auto;
            margin-bottom: convertPxToVhVw(20, 'vh', 758);
            max-width: 220px;
            height: convertPxToVhVw(151, 'vh', 758);
            max-height: 151px;
            @include displayFlex(center, center);

            img {
              max-width: 115px;
              max-height: 130px;
            }
          }

          .extension-text {
            max-width: 250px;
            height: auto;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.01em;
            margin: 0 auto;
            display: block;
            white-space: pre-line;

            &.section-2 {
              margin-top: convertPxToVhVw(20, 'vh', 758);
              margin-bottom: convertPxToVhVw(10, 'vh', 758);
            }
          }
        }
      }

      ul.slick-dots-bottom {
        bottom: -40px !important;
        margin: 0 !important;

        li {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin-right: 10px;
          margin-left: 10px;

          button {
            border-radius: 50%;
            height: 100%;
            background-color: $Neutral-2;
            pointer-events: none;
            opacity: 1 !important;
          }

          &.slick-active button {
            background-color: $dark;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .welcome-carousel-container {
    .content-frame-wrapper {
      margin-top: 42px;
      padding: 0;
    }
  }
}

// desktop design
@media only screen and (min-width: 769px) {
  .welcome-carousel-container {
    padding-top: 0px;
    align-items: flex-start !important;

    .content-frame-wrapper {
      padding: 0;
    }

    .next {
      bottom: 46px;
      right: 39px;
    }

    .ant-carousel {
      border-radius: 40px;

      .welcome-carousel {
        border-radius: 40px;

        div {
          border-radius: 40px;

          .carousel-element {
            .title {
              font-size: 36px;
              line-height: 43px;
              width: auto;
              margin-bottom: convertPxToVhVw(18, 'vh', 930);
            }

            .image-container {
              margin-bottom: convertPxToVhVw(13, 'vh', 930);
              max-width: convertPxToVhVw(294, 'vw', 930);
              height: convertPxToVhVw(250, 'vh', 930);
              max-height: 250px;

              img {
                max-width: 150px;
                max-height: 165px;
              }
            }

            .extension-text {
              @include textTypography(20px, 400, 0.2px);
              max-width: 300px;

              &.section-2 {
                margin-top: convertPxToVhVw(25, 'vh', 930);
              }
            }
          }
        }

        ul.slick-dots-bottom {
          bottom: 57px !important;

          li {
            &.slick-active button {
              background-color: $dark;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-height: 940px) {
    .welcome-carousel-container {
      .content-frame-wrapper {
        .desktop-header-container {
          height: 60px !important;
          overflow: unset;

          .separation {
            display: none;
          }
        }
      }

      .ant-carousel {
        .welcome-carousel {
          div .carousel-element {
            .title {
              font-size: 32px;
            }

            .image-container {
              margin-bottom: convertPxToVhVw(30, 'vh', 832);
            }
          }
        }
      }
    }
  }

  // small laptops bellow height 800px
  @media only screen and (max-height: 800px) {
    .welcome-carousel-container {
      .ant-carousel {
        border-radius: 0px;

        .welcome-carousel {
          border-radius: 0px;

          div {
            border-radius: 0px;

            .carousel-element {
              .title {
                font-size: 30px;
                line-height: 38px;
              }

              .image-container {
                margin-bottom: convertPxToVhVw(30, 'vh', 707);

                img {
                  max-width: 120px;
                  max-height: 135px;
                }
              }

              .extension-text {
                @include textTypography(16px, 400, 0.16px);
              }
            }
          }

          ul.slick-dots-bottom {
            bottom: convertPxToVhVw(57, 'vh', 944) !important;
          }
        }
      }
    }
  }

  @media only screen and (min-height: 960px) {
    .welcome-carousel-container {
      .ant-carousel .welcome-carousel div .carousel-element {
        .image-container {
          margin-bottom: convertPxToVhVw(20, 'vh', 930);
        }

        .extension-text.section-2 {
          margin-top: convertPxToVhVw(30, 'vh', 930);
          margin-bottom: convertPxToVhVw(10, 'vh', 930);
        }
      }
    }
  }
}
