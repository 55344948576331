@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/colors.scss';

.personal-info {
  .desktop-label-text, .mobile-label-text {
    @include fontFootnote;
  }

  .sub-label-text {
    @include fontCaption;
    letter-spacing: .1px;
    line-height: normal;
    color: $Neutral-500;
  }

  section {
    @include displayFlex(space-between, center);
    height: auto;
    margin-bottom: 20px;
    position: relative;
    padding: 10px 0;

    &.edit-phone .edit-input-container {
      max-width: fit-content;
    }
    &.edit-phone h4 {
      cursor: pointer;
    }

    label,
    .title {
      @include fontFootnote;
      line-height: 18px;
      color: $black;
      cursor: pointer;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
    }

    .desktop-label-text {
      display: none;
    }

    input,
    h4 {
      @include fontBody;
      letter-spacing: .16px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      color: $purple-900;
    }

    .edit-input-container {
      background-color: $purple-100;
      border-radius: 5px;
      padding: 5px 10px;
      min-height: 29px;
      overflow: hidden;
    }

    input {
      border: none;
      background-color: transparent;
      text-align: end;
      padding: 0;
      width: 100%;

      &.phone {
        max-width: 102px;
      }

      &:focus {
        border: none;
        background-color: transparent;
        outline: none;
      }
    }

    .flex {
      @include displayFlex(flex-end, center);
      cursor: pointer;
      flex-wrap: nowrap;
      width: 147px;
    }

    &.notification,
    &.data {
      p {
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.1px;
        color: $Neutral-500;
      }

      .personal-info-btn {
        border-radius: 5px;
        height: 30px;
        padding: 0;
        letter-spacing: 0.12px;
        min-width: 109px;
        @include fontFootnote;
      }

      .button-container {
        display: flex;
        gap: 10px;
      }
    }
  }
}

@include mobileView {
  .personal-info {
    .confirm-modal p.confirm-modal-info {
      white-space: pre-line;
    }
  }
}

@include desktopView {
  .personal-info {
    margin-bottom: 40px;

    section {
      .desktop-label-text {
        display: inline-block;
      }

      .mobile-label-text {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width: $desktopScreenMinWidth) and (max-width: 1000px) {
  .personal-info {
    section {
      &.notification p,
      &.data p,
      label span {
        white-space: pre-line !important;
      }
    }
  }
}
