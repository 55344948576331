@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/sharedStyle.index.scss';

.calendar-sync-popover-container {
  padding: 20px;
  min-height: 157px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 22px;

  .title-and-back-section {
    @include displayFlex(flex-start, center);
  }

  .calendar-sync-overlay-header-back {
    @include transparencyBtn;
    margin-right: 8px;
  }

  .calendar-sync-overlay-header-text {
    @include fontBody;
    color: $appDarkColor;
    margin: auto;
  }
}

.calendar-sync-popover-title {
  color: $Neutral-3;
  font-family: 'Dosis' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
}

.calendar-sync-popover-buttons,
.calendar-sync-type-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    @include fontBody;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid;
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-right: 0;
    white-space: nowrap;
    color: $appDarkColor;

    &.calendar-sync-popover-google-button {
      color: $appDarkColor;
      border: 1px solid $Neutral-2;
      background-color: $white;
      margin-top: 10px;
    }

    &.calendar-sync-popover-sync-option {
      background-color: $purple-200;
      border-radius: 8px;
      color: $dark;
      border-color: $Neutral-2;
      margin: 10px auto;
      border: none;
      padding: 0;
      .calendar-sync-button-content-container {
        width: 100%;
      }
      .title-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        img {
          margin-right: 5px; 
        }
      }
      .title {
        @include fontBody;
      }
      .subTitle {
        @include fontCaption;
        color: $purple-500;
        width: 100%;
        text-align: center;
      }
    }

    &.calendar-sync-popover-microsoft-outlook-button {
      background-color: $black;
      color: $white;
      border-color: $Neutral-2;
      margin: 10px auto;
    }

    &.calendar-sync-popover-ical-button {
      border: transparent;
      padding-left: 47px;
      background-color: $purple-200;
    }

    img {
      margin-right: 13px;
    }
  }
}

.calendar-sync-type-buttons {
  padding-top: 30px;

  .title {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    color: $black;
    cursor: pointer;
    margin-left: 0;
  }

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    color: $Neutral-3;
  }

  button {
    width: 288px;
    height: 60px;
  }
}

.calendar-sync-google-button,
.calendar-sync-outlook-synced,
.calendar-sync-ical-synced {
  margin-bottom: 30px;
  background-color: $purple-100;
  color: $black;
  width: 105px;
  height: 40px;
  border-radius: 5px;
  border: none;
  border-color: $Neutral-2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  pointer-events: none;
  padding: 0;

  img {
    margin-right: 13px;
  }
}

.calendar-sync-outlook-synced {
  background-color: $black;
  color: $white;

  img {
    margin-right: 8px;
  }
}

.calendar-sync-ical-synced {
  img {
    margin-right: 8px;
  }
}

.calendar-sync-flex {
  width: inherit !important;
}

.calendar-sync-remove {
  padding-bottom: 17%;
  margin-right: 15px;
  font-family: 'Lato' !important;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-decoration-line: underline;
  color: $Neutral-3;
  border: transparent;
  background-color: transparent;

  img {
    margin-right: 5px;
  }
}

.calendar-sync-popover-divider {
  margin-bottom: 10px;
  color: $Neutral-3;
}
