@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixin.scss';
@import '../../../../../../styles/layout.scss';

.dislike-feedback-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  padding: 20px 19px 66px;

  // Font-family for all the elements on this screen should be 'Lato' when compared to the other screens in the app.
  * {
    font-family: 'Lato' !important;
    letter-spacing: 0.01em;
  }

  .dislike-frame {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    @include displayFlex(flex-start, flex-start, column);
    padding: 26px 31px 26px 41px;
    overflow: hidden;
    overflow-y: auto;
    @include boxShadow();

    button.cancel {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      text-align: right;
      align-self: flex-end;
      background-color: transparent;
      padding: 0;
      border: transparent;
      border-bottom: 1px solid;
      color: $dark;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.01em;
      margin-top: 17px;
      margin-bottom: 25px;
    }

    p {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      color: $Neutral-3;
      text-align: left;
    }

    section.optional-buttons {
      width: 100%;
      @include displayFlex(flex-start, flex-start, row, wrap);
      margin-top: 31px;
      margin-bottom: 34px;

      button {
        display: block;
        height: 30px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        padding: 0 10px;
        background-color: $Neutral-1;
        border-radius: 50px;
        border: transparent;
        margin-right: 11px;

        &:last-child {
          margin-right: 0;
        }

        &.selected {
          border: 1px solid $dark;
        }
      }

      .option-container {
        margin-right: 11px;

        label {
          display: block;
          height: 30px;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          padding: 0 10px;
          background-color: $Neutral-1;
          border: 1px solid $Neutral-1;
          border-radius: 50px;
          @include displayFlex(center, center);
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &:has(input[type='checkbox']:checked) {
            background-color: $Neutral-2;
          }

          input[type='checkbox'],
          input[type='radio'] {
            position: absolute;
            opacity: 0;
            left: -9999px;
          }
        }
      }
    }

    form {
      width: 100%;
      @include displayFlex(flex-start, flex-start, column);

      .textarea-error-container {
        width: 100%;
        position: relative;

        textarea {
          width: 100%;
          height: convertPxToVhVw(184, 'vh', 758);
          resize: none;
          border-radius: 8px;
          background-color: $Neutral-1;
          border: transparent;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          padding: 18px;
          color: $dark;

          &::placeholder {
            color: $Neutral-2;
          }

          &:focus {
            outline: none;
          }
        }

        .app-error {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          color: $dark;
          position: absolute;
          bottom: 20px;
          left: 18px;
        }
      }

      .d-flex {
        width: 100%;
        @include displayFlex(flex-start, flex-start, column);

        button[type='submit'] {
          align-self: center;
          border: 1px solid $dark;
          background-color: $dark;
          color: white;
          border-radius: 5px;
          width: 100px;
          height: 30px;
          margin-top: 23px;
          font-size: 14px;
          margin-bottom: 27px;
        }

        .discord-link,
        .discord-link * {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.12px;
        }
      }
    }
  }
}

@include mobileView {
  .dislike-feedback-container {
    background: var(--appMobileBackground);
    background-size: cover;

    &:has(textarea:focus) {
      overflow-y: scroll;

      .dislike-frame {
        height: fit-content;
        overflow: unset;
      }
    }

    .dislike-frame {
      background-color: $white;
    }
  }
}

@media only screen and (max-width: 360px) {
  .dislike-feedback-container .dislike-frame {
    section.optional-buttons button:first-child {
      margin-bottom: 11px;
    }
  }
}

@include desktopView {
  .dislike-feedback-container {
    background-color: $Neutral-4;
    padding: 35px 42px;

    .dislike-frame {
      border: none;
      box-shadow: none;
      padding: 0;

      button.cancel {
        font-size: 16px;
      }

      h2 {
        margin-top: convertPxToVhVw(32, 'vh', 944);
        margin-bottom: convertPxToVhVw(20, 'vh', 944);
      }

      p {
        white-space: unset;
      }

      section.optional-buttons {
        margin-top: convertPxToVhVw(30, 'vh', 944);
        margin-bottom: convertPxToVhVw(30, 'vh', 944);
      }

      form {
        .textarea-error-container {
          textarea {
            height: convertPxToVhVw(131, 'vh', 944);
          }

          .app-error {
            bottom: 22px;
            left: 14px;
          }
        }

        .d-flex {
          @include displayFlex(space-between, center, row-reverse);
          margin-top: convertPxToVhVw(19, 'vh', 944);

          button[type='submit'] {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-height: 580px) {
  .dislike-feedback-container {
    padding-right: $scrollbarMarginRight;

    .dislike-frame {
      padding-right: 10px;
    }
  }
}
