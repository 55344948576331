@import '../../../../../../../styles/sharedStyle.index.scss';

$checkboxSize: 18px;
$nameControllerPaddingBottom: 19px;
$nameControllerPaddingLeft: 32px;

.add-edit-task-container .task-name-controller.task-detail-section,
.work-block-dialog-container .work-block-name-controller.work-block-detail-section {
  padding-bottom: $nameControllerPaddingBottom;
  @include displayFlex(flex-start, center);
  position: relative;
  margin-top: 1px !important;
  padding-left: $nameControllerPaddingLeft;

  label {
    position: absolute;
    left: 0;
    bottom: calc($nameControllerPaddingBottom - 3px);
    @include appCheckboxUsingBeforeAndAfter($taskDetailsPlaceholderColor, $checkboxSize);
    
    &::before,
    &::after {
      top: unset;
      bottom: 0;
      transform: unset;
    }
    // on active
    &::after{
      background-color: $appDarkColor;
      border-color: $appDarkColor;
    }
  }

  input.name-text {
    padding: 0;
    border: none;
    background-color: transparent;
    @include fontBody;
    width: calc(100% - $nameControllerPaddingLeft);
    color: $taskDetailsTextColor;

    &::placeholder {
      color: $taskDetailsPlaceholderColor;
    }

    &:focus {
      outline: none;
    }
  }

  .task-name-error {
    @include textTypography(8px, 400, 0.08px);
    color: $Brand-4;
    position: absolute;
    bottom: -20px;
    left: $nameControllerPaddingLeft;
  }

  .priority-and-quick-win {
    @include displayFlex(flex-end, center);

    button {
      @include transparencyBtn;
    }
  }

  &--read-only {
    padding-left: 0 !important;
  }
}

@include mobileView {
  .task-name-controller,
  .work-block-name-controller {
    margin: 0 auto;

    .priority-and-quick-win {
      button {
        &.priority {
          margin-left: 29px;
        }
      }
    }
  }
}

@include desktopView {
  .task-name-controller,
  .work-block-name-controller {
    width: 100%;
    justify-content: space-between;

    .priority-and-quick-win {
      button {
        &.priority {
          margin-left: 29px;
        }
      }
    }
  }
}
