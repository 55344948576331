@import '../../../../../../styles/sharedStyle.index.scss';

.edit-reminder-time-value-container {
  @include displayFlex(center, center);

  .reminder-time-text {
    @include textTypography(16px, 400, 0.16px);
  }
}

.proactive-reminder-timer-overlay-container {
  width: 100%;
  padding: 20px 41px 111px;

  .proactive-reminder-timer {
    margin-top: 30px;

    .app-timer-minute-picker {
      display: none;
    }
  }
}

@include mobileView {
  .edit-reminder-time-value-container {
    .reminder-time-text {
      @include textTypography(14px, 400, 0.14px);
    }
  }
}

@include desktopView {
  .proactive-reminder-timer-overlay-container {
    padding: 20px 20px 34px;

    .proactive-reminder-timer {
      margin-top: 17px;
    }
  }
}
