@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.avatar {
  border: 2px solid $white;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.default-avatar {
    font-family: 'Dosis' !important;
    height: 32px;
    width: 32px;
    border: none;
    font-weight: 500;
    font-style: normal;
    color: $dark;
    background: linear-gradient(180deg, $Brand-1 0%, $white 100%) !important;
    @include displayFlex(center, center);
  }
}
