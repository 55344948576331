@import '../../../../../../styles/sharedStyle.index.scss';

.task-overlay-quick-options-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}

.task-overlay-flex-options-container {
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-overlay-quick-options-single-option {
  width: 110px;
  height: 100px;
  border-radius: 8px;
  background: $Neutral-1;
  color: $appDarkColor;

  &--selected {
    background: $Neutral-3;
  }
}

.task-overlay-quick-options-single-option-content-container,
.task-overlay-quick-options-custom-option-container {
  margin-top: -4px;

  .quick-options-title {
    @include textTypography(14px, 700);
    color: $appDarkColor;
    margin-bottom: 16px;
  }

  .quick-option-content {
    @include textTypography(16px, 400);
  }
}

.task-overlay-quick-options-custom-option-container {
  background-color: transparent !important;
  @include displayFlex(flex-end, flex-start, column);
  cursor: unset;

  h3 {
    @include textTypography(12px, 400);
  }
}
