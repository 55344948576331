@import '../../../../../styles/sharedStyle.index.scss';
@import '../../../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/singleTaskItemContainer.module.scss';

.stage-tasks-container {
  @include displayFlex(flex-start, flex-start, column);
  text-align: start;
  height: 100%;
  width: 100%;
  background-color: transparent;

  .scrollable-container {
    flex: 1;
    overflow-y: auto;
    @include designedScrollbar(
      $scrollbarTopDistance,
      $scrollbarBottomDistance + $desktopChatFormInputHeight
    );

    &:has(.tasks-list-item:not(.subtask-item)) {
      .stage-tasks-empty-state {
        display: none;
      }
    }

    &:not(:has(.tasks-list-item:not(.subtask-item))) {
      .stage-tasks-empty-state {
        display: flex;
      }
    }

    .tasks-group {
      margin-top: 0px;

      .group-title {
        display: none;
      }

      .tasks-list-item {
        &.subtask-item {
          display: none !important;
        }
      }
    }
  }

  input.add-main-task-input {
    height: 30px;
    border-radius: 5px;
  }
}

@include mobileView {
  .stage-tasks-container {
    padding: 10px 22px 0 20px;

    .scrollable-container {
      width: 100%;
      padding: 0;
      background-color: $white-transparency-2;
      border-radius: 8px 8px 0px 0px;

      .swipeable-item-container {
        height: $singleTaskHightStageTasks !important;
      }

      .tasks-group {
        .swipeable-item-right-side .task-action-button {
          border-left: none;
        }

        .swipeable-item-left-side .task-action-button {
          border-right: none;
        }
      }
    }

    input.add-main-task-input {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

@include desktopView {
  $scrollablePaddingRight: calc($stageContentPaddingRightDesktop - $scrollbarMarginRight);

  .stage-tasks-container {
    .scrollable-container {
      width: calc(100% - $scrollbarMarginRight);
      padding: 42px $scrollablePaddingRight 42px $stageContentPaddingLeftDesktop;
      overflow-x: hidden;
      @include designedScrollbar($scrollbarTopDistance + 30px);
    }

    input.add-main-task-input {
      margin-top: 11px;
    }
  }

  .display-stage.stage-expanded .stage-tasks-container {
    .scrollable-container {
      max-width: calc($stageContentMaxWidthOnExpendMode + ($scrollbarMarginRight * 2));
      margin: 0 auto;
      padding-left: $scrollbarMarginRight;
      padding-right: $scrollbarMarginRight;
    }
  }
}