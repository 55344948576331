@import '../../../../../../../styles/sharedStyle.index.scss';

$colorCircleSize: 20px;

.task-tags-overlay {
  width: 100%;
  margin-top: 0;

  input {
    @include fontBody;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $Neutral-3;
    padding-bottom: 10px;
    display: block;
    width: 100%;
    color: $taskDetailsTextColor;
    margin-bottom: 21px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $Neutral-500;
    }
  }

  .colors-container {
    @include displayFlex(flex-start, center);
    width: 100%;
    gap: 9px;

    button.tag-color {
      width:$colorCircleSize;
      min-width:$colorCircleSize;
      max-width:$colorCircleSize;
      height: $colorCircleSize;
      min-height: $colorCircleSize;
      max-height: $colorCircleSize;
      border-radius: 50%;
      border: 3px solid transparent;
      padding: 0;

      &.selected {
        border: 2px solid $dark;
        outline: 1px solid $white;
        outline-offset: -3px;
      }

      &.transparent {
        border: 1px solid $Neutral-2;

        &.selected {
          border: 3px solid $dark;
          outline: none;
        }
      }
    }
  }

  .recent-tags-container {
    margin-top: 20px;

    .recent-tags-title {
      @include fontCaption;
      height: 16px;
      color: $purple-600;
      @include displayFlex(flex-start, flex-end);
      margin-bottom: 16px;
    }

    .recent-tags-list {
      @include displayFlex(flex-start, flex-start, row, wrap);
      row-gap: 7px;

      .single-tag {
        height: 28px;
        min-width: 50px;
        @include displayFlex(center, center);
        border-radius: 8px;
        cursor: pointer;
        @include fontFootnote;
      }
    }
  }
}
