@import '../../styles/sharedStyle.index.scss';

.chat-wrapper {
  height: 100%;
  flex: 1;

  > div {
    /* Fallback for browsers that do not support Custom Properties */
    height: 100vh;
    height: calc(var(--availableViewportHeight) * 100);
  }
}

@include mobileView {
  .chat-wrapper {
    height: calc(var(--availableViewportHeight) * 100);
  }
}

.proactive-survey-overlay-mobile {
  border: unset;
  width: 90%;
  background-color: white;
}

.proactive-survey-overlay-desktop {
  border: unset;
  width: 455px;
  background-color: transparent;
}
