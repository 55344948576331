@import '../../../styles/sharedStyle.index.scss';

.app-pop-up-toast {
  width: 100%;
  padding: 16px 8px;
  @include displayFlex(flex-start, flex-start, column);

  button {
    @include transparencyBtn;
    align-items: center;
    justify-content: center;
  }

  &--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .app-pop-up-toast-close-icon {
    width: 24px;
    height: 24px;
  }

  &--title,
  &--body {
    color: $appDarkColor;
  }

  &--title,
  &--body {
    width: 90%;
  }

  &--title {
    @include fontTitle1;
  }

  &--body {
    margin: 24px 0;
    display: block;
    @include fontFootnote;
  }

  &--actions {
    @include displayFlex(flex-start, center);
    width: 100%;
    column-gap: 25px;
    
    &--dont-show-again,
    &--cta {
      text-decoration: none;
      text-decoration-line: underline;
      color: $appDarkColor;
      @include fontFootnote;
    }
  }
}

#discord-toast {
  .app-pop-up-toast {
    &--title {
      @include fontBody;
      margin-bottom: 24px;
    }
  }
}

// custom style for AppOverlayPopover
.app-overlay-popover-background-container {
  .app-overlay-popover-content-container {
    &.app-toast-overlay-container {
      max-width: 349px !important;
      min-height: 100px;
      border-radius: 8px 8px 8px 8px !important;
      border: none !important;
      box-shadow: none !important;
      background-color: $teal-400 !important;
      overflow: hidden !important;
    }
  }
}

@include mobileView {
  .app-overlay-popover-background-container .app-overlay-popover-content-container {
    &.app-toast-overlay-container {
      top: unset !important;
      left: 50% !important;
      bottom: $appToastMobileBottomPosition !important;
      transform: translate(-50%, 0%) !important;
      width: 90%;
      margin: 0 auto;
    }
  }
}

@include desktopView {
  .app-overlay-popover-background-container .app-overlay-popover-content-container {
    &.app-toast-overlay-container {
      min-width: 349px !important;
      left: unset !important;
      top: unset !important;
      transform: unset !important;
      right: 50px !important;
      bottom: 50px !important;
    }
  }
}
