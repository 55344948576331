@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixin.scss';

.invite-friends-card-container {
  height: auto;
  border-radius: 10px;
  border: 1px solid $Neutral-2;
  padding: 30px 20px 25px 20px;
  margin-bottom: 50px;
}
