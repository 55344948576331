@import '../../../../../../styles/sharedStyle.index.scss';
@import '../WorkBlockDetailsContainer.scss';

.work-block-form {
  .work-block-controller {
    margin-bottom: 30px;
  }

  .save-button,.delete-item {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
