@import '../../../../../../styles/sharedStyle.index.scss';

$dropdownItemsMarginRight: 15px;
$dropdownMinWidth: 88px;
$dropdownToggleButtonHeight: 19px;

.plan-view-selection {
  position: relative;
  display: inline-block;
  min-width: $dropdownMinWidth;
  transition: width 0.4s ease-out;
  width: 117px;

  .dropdown-toggle {
    @include displayFlex(flex-start, center);
    @include fontBody;
    color: $appDarkColor;
    height: $dropdownToggleButtonHeight;
    padding: 0px;
    border-radius: 5px;
    border: transparent;
    background: transparent;
    white-space: nowrap;
  }
  
  .hamburger-icon{
    margin-right: 8px;
  }

  .dropdown-menu {
    position: absolute;
    top: -15px;
    left: -17px;
    background-color: $Neutral-4;
    width: 140px;
    overflow: hidden;
    height: 0;
    transition: height 0.4s ease-out;
    z-index: $stageTasksDropdownFilterOverlayZIndex;
    border-radius: 16px;
    text-align: left;

    .dropdown-view {
      @include fontFootnote;
      color: $appDarkColor;
      @include displayFlex(flex-start, center);
      height: fit-content;
      cursor: pointer;
      margin-bottom: 13px;
      margin-top: 12px;
      margin-left: 17px;
    }

    .dropdown-item {
      @include transparencyBtn;
      cursor: pointer;
      white-space: nowrap;
      @include fontBody;
      color: $Neutral-500;
      margin-bottom: 16px;
      margin-left: 17px;
      height: 20px;

      &.active{
        color: $purple-600;
      }
    }
  }

  &.open {
    .dropdown-toggle {
      transition: 0.5s;
    }

    .dropdown-menu {
      height: 165px;
      box-shadow: 0px 10px 4px 0px $black-transparency-025;
      border: 1px solid $Neutral-1;;
    }
  }
}

@include mobileView {
  .plan-view-selection {
    display: none;
    margin-top: 2px;
  }

  body {
    &:has(.display-stage .chat .stage-tasks-container),
    &:has(.display-stage .chat .stage-planner-container) {
      .plan-view-selection {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .plan-view-selection {
        display: none !important;
      }
    }
  }
}

// hide dropdown when message dislike feedback modal is open
body {
  &:has(.chat .message .dislike-feedback-container) {
    .plan-view-selection {
      display: none !important;
    }
  }
}
