@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixin.scss';

.in-chat-tasks-list-container {
  padding: 30px 15px 20px;

  // items in the chat card should not open the edit-task-form
  .tasks-list-item .task-name-container {
    pointer-events: none;
  }
  .single-task-container .tasks-list-item {
    cursor: default !important;
    .task-item-left {
      .task-complete-label {
        cursor: pointer;
      }
    }
  }
}

.tasks-group {
  margin-top: 15px;

  // this style use for tasks chat card and stage-tasks list both
  .group-title {
    @include textTypography(10px, 400, 1px);
    border-bottom: 1px solid $Neutral-2;
    padding-bottom: 10px;
    color: $Neutral-3;
    margin-bottom: 10px;
  }

  &:first-child {
    margin-top: 0px !important;
  }

  &.overdue {
    .group-title {
      color: $Brand-4;
      border-bottom-color: $Brand-4;
    }
  }

  // hide groups that don't include any displayed main tasks(that are not subtasks).
  &:not(:has(.tasks-list-item.displayed:not(.subtask-item))) {
    display: none;
  }

  .subtask-item {
    display: none;
  }
}

// All cards other than the last one are read-only.
.history-messages .card-wrapper .tasks-group,
.form-fields .card-wrapper:not(:last-of-type) .tasks-group {
  .tasks-list-item {
    pointer-events: none;

    input[type='checkbox'] {
      pointer-events: none;
    }
  }
}