@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixin.scss';

.content-frame-wrapper {
  .open-close-menu-btn-container {
    @include displayFlex(center, center);
    position: relative;

    .close-settings,
    .avatar-button {
      border: transparent;
      background-color: transparent;
      padding: 0;
      position: absolute;
      top: auto;
    }

    .close-settings {
      right: 3px;
      &.fadeOut {
        animation-duration: 1s;
      }
    }

    .avatar-button {
      right: 0px;
      padding: 0;
      border-radius: 50%;
      &.fadeIn {
        animation-duration: 1s;
      }

      .header-avatar.default-header-avatar {
        font-size: 15px;
      }
    }
  }
}

@include desktopView {
  .content-frame-wrapper {
    .open-close-menu-btn-container {
      display: none !important;
    }
  }
}
