@import '../../../../../styles/sharedStyle.index.scss';

.stage-container {
  .add-task-input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: $purple-200;
    border: transparent;
    @include fontBody;
    padding: 0 37px;
    padding-bottom: 1px;
    color: $purple-900;

    &:focus {
      outline: none;
    }
    &::placeholder{
      color: $Neutral-500;
    }
  }
}
