@import '../../../../../styles/mixin.scss';

.open-close-history-btn-container {
  @include displayFlex(center, center);
  position: relative;

  .close-history,
  .history-header-icon {
    border: transparent;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: auto;
    left: 0px;
  }

  .close-history {
    left: -2px;
  }

  .history-header-icon {
    border-radius: 50%;
  }
}
