@import '../../../../../../styles/sharedStyle.index.scss';

$dropdownItemsMarginRight: 15px;
$dropdownMinWidth: 88px;
$dropdownToggleButtonHeight: 19px;

.stage-tasks-dropdown-filter {
  position: relative;
  display: inline-block;
  min-width: $dropdownMinWidth;
  transition: width 0.4s ease-out;
  width: 117px;

  .dropdown-toggle {
    @include displayFlex(flex-end, center);
    @include textTypography(16px, 700, 0.16px);
    color: $white;
    height: $dropdownToggleButtonHeight;
    padding: 0px;
    border-radius: 5px;
    border: transparent;
    background: transparent;
    white-space: nowrap;
    width: 100%;

    &--arrow-down {
      margin-top: 5px;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: -15px;
    left: -11px;
    background-color: $Neutral-4;
    width: 140px;
    overflow: hidden;
    height: 0;
    transition: height 0.4s ease-out;
    z-index: $stageTasksDropdownFilterOverlayZIndex;
    border-radius: 16px;
    text-align: left;

    .dropdown-view {
      @include fontFootnote;
      color: $appDarkColor;
      @include displayFlex(flex-end, center);
      height: fit-content;
      cursor: pointer;
      margin: 12px 12px 13px 17px;
    }

    .dropdown-item {
      @include transparencyBtn;
      cursor: pointer;
      white-space: nowrap;
      @include fontBody;
      color: $Neutral-500;
      margin-bottom: 16px;
      margin-left: 17px;
      height: 20px;
      
      &.active{
        color: $purple-600;
      }
    }
  }

  &.open {
    .dropdown-toggle {
      transition: 0.4s;
    }

    .dropdown-menu {
      height: 244px;
      box-shadow: 0px 10px 4px 0px $black-transparency-025;
      border: 1px solid $Neutral-1;
    }
  }
}

@include mobileView {
  .stage-tasks-dropdown-filter {
    display: none;
  }

  body {
    &:has(.display-stage .chat .stage-tasks-container),
    &:has(.display-stage .chat .stage-planner-container) {
      .stage-tasks-dropdown-filter {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .stage-tasks-dropdown-filter {
        display: none !important;
      }
    }
  }
}

@include desktopView {
  .stage-tasks-dropdown-filter {
    margin-left: 35px;
  }
}

// hide dropdown when message dislike feedback modal is open
body {
  &:has(.chat .message .dislike-feedback-container) {
    .stage-tasks-dropdown-filter {
      display: none !important;
    }
  }
}
