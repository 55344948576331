@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';

.terms-of-service-container {
  width: 100%;
  text-align: center;
  max-width: 215px;
  .terms-of-service-content {
    color: $Neutral-3;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
