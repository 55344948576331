@import './sharedStyle.index.scss';

// this file includes the animation app in mobile view when the keyboard/taskForm is open or close
@include mobileView {
  // animation while keyboard is open(focus) outside the taskForm / while open task-form
  body.input-focused:not(:has(.stage-container .details-dialog-animation-container)),
  body:has(.stage-container .details-dialog-animation-container:not(.slideOutDown)),
  body:has(.stage-container .plan-tasks-placement-list-container--full),
  body:has(.stage-container .plan-tasks-placement-list-container--placing) {
    .resizable-container {
      .chat {
        margin-top: 0;
        padding-bottom: calc($mobileChatFormFocusedInputHeight);

        .conversation {
          padding-top: 20px;
        }
      }

      .navbar-container {
        display: none;
      }

      .form {
        transform: translateY($mobileNavbarHeight);
        height: $mobileChatFormFocusedInputHeight;

        &:has(.send-button) {
          height: auto;
          min-height: $mobileChatFormFocusedInputHeight;
        }

        section {
          padding: 0 !important;
          border: none;
        }
      }

      header {
        overflow: hidden;
        height: 0;
      }
    }
  }

  // animation while keyboard(blur)/taskForm getting close
  body.keyboard-close:not(:has(.stage-container .details-dialog-animation-container)),
  body:has(.stage-container .details-dialog-animation-container.slideOutDown) {
    .resizable-container {
      header {
        overflow: visible;
        height: $mobileHeaderHeight;
      }

      .chat {
        margin-top: $mobileHeaderHeight;
        padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight);
      }

      .chat .form {
        transform: translateY(0px);
      }

      .navbar-container {
        display: flex;
      }
    }
  }

  body.input-focused {
    .proactive-survey-popup {
      position: fixed;
      bottom: calc($mobileChatFormInputHeight + 8px);
      width: 90%;
    }
  }
}

@keyframes shrinkOutNavBarAnimation {
  0% {
    height: $mobileNavbarHeight;
  }

  100% {
    height: 0px;
  }
}

@keyframes growInNavBarAnimation {
  0% {
    height: 0px;
  }

  100% {
    height: $mobileNavbarHeight;
  }
}

@keyframes shrinkOutHeaderAnimation {
  0% {
    height: $mobileHeaderHeight;
  }

  100% {
    height: 0px;
  }
}

@keyframes growInHeaderAnimation {
  0% {
    overflow: hidden;
    height: 0px;
  }

  100% {
    overflow: visible;
    height: $mobileHeaderHeight;
  }
}

@keyframes formPositionKeyboardOpenAnimation {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY($mobileNavbarHeight);
  }
}

@keyframes formPositionKeyboardCloseAnimation {
  0% {
    transform: translateY($mobileNavbarHeight);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes chatContainerKeyboardOpenAnimation {
  0% {
    margin-top: $mobileHeaderHeight;
    padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight);
  }

  100% {
    margin-top: 0;
    padding-bottom: calc($mobileChatFormInputHeight);
  }
}

@keyframes chatContainerKeyboardCloseAnimation {
  0% {
    margin-top: 0;
    padding-bottom: calc($mobileChatFormInputHeight);
  }

  100% {
    margin-top: $mobileHeaderHeight;
    padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight);
  }
}
