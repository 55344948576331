@import '../../styles/mixin.scss';

.init-user-guard-container {
    @include displayFlex(center, center);
    width: 100vw;
    height: 100vh;
  }
  
  @include mobileView {
    .init-user-guard-container {
      background: var(--appMobileBackground);
      background-size: cover;
    }
  }
  
  @include desktopView {
    .init-user-guard-container {
      background: var(--appDesktopBackground);
      background-size: cover;
    }
  }