@import '../../../../../styles/colors.scss';

.download-session {
  margin-bottom: 40px;

  button {
    display: block;
    min-width: 100px;
    height: 30px;
    background-color: $dark;
    border-radius: 5px;
    color: $white;
  }

  small {
    color: $Brand-4;
    font-size: 12px;
  }

  #session-selector {
    margin-bottom: 0.5rem;
    width: fit-content;
  }
}
