@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.welcome-screen-container {
  height: 100vh;
  height: calc(var(--availableViewportHeight) * 100);
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 99999;
  background-size: cover !important;

  .welcome-screen-frame {
    background-color: $white;
    border: 1px solid $dark;
    @include displayFlex(space-between, center, column);
    padding-top: 6vh;
    overflow: hidden;
    height: 87%;

    .welcome-screen-content-container {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 5vh;
    }

    .welcome-screen-description {
      white-space: pre-line;
    }

    .lets-go-btn {
      width: 100px;
      height: 40px;
      min-height: 40px;
      padding: 0;
      border: 1px solid $dark;
      border-radius: 5px;
      background-color: $dark;
      @include textTypography(14px, 400, 0.14px);
      color: $Neutral-1;
    }
  }
}

@media only screen and (max-width: 768px) {
  .welcome-screen-container {
    background: $mobile-onboarding-BG;
    padding-top: 50px;

    .welcome-screen-frame {
      padding-bottom: 6vh;

      .welcome-screen-title {
        @include textTypography(24px, 500, 0.24px);
        margin-bottom: 7vh;
      }

      .mascot {
        width: 85px;
      }

      .welcome-screen-description {
        @include textTypography(16px, 400, 0.16px);
        margin-top: 7vh;
      }
    }
  }
}

@media only screen and (max-width: 768px) and (max-height: 550px) {
  .welcome-screen-container {
    .welcome-screen-frame {
      .welcome-screen-content-container {
        margin-bottom: 4vh;
      }
      .welcome-screen-title {
        margin-bottom: 3vh;
      }
      .welcome-screen-description {
        margin-top: 3vh;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .welcome-screen-container {
    background: $desktop-onboarding-BG;

    .welcome-screen-frame {
      padding-bottom: 10vh;

      .welcome-screen-title {
        @include textTypography(36px, 500, 0.36px);
        margin-bottom: 11vh;
      }

      .welcome-screen-description {
        @include textTypography(20px, 400, 0.2px);
        margin-top: 8vh;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-height: 650px) {
  .welcome-screen-container {
    .welcome-screen-frame {
      .welcome-screen-content-container {
        margin-bottom: 4vh;
      }
      .welcome-screen-title {
        margin-bottom: 5vh;
      }
      .welcome-screen-description {
        margin-top: 5vh;
      }
    }
  }
}
