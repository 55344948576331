@import '../../../styles/mixin.scss';
@import '../../../styles/layout.scss';
@import '../../chat-wrapper/resizable-container/stage-container/stage-planner/StagePlannerVariables.scss';

$taskCompleteLabelMarginRight: 8px;
.plan-tasks-placement-list-container,
.plan-tasks-placement-list-container--mobile,
.plan-tasks-placement-list-container--full {
  transition: all 0.5s ease;
}

.plan-tasks-placement-list-inner-container {
  overflow: hidden;
  height: 100%;
  border-radius: 8px;
  .plan-tasks-placement-list-actions-container {
    display: flex;
    align-items: center;
  }

  .plan-tasks-placement-current-task-container {
    text-align: left;
    .plan-tasks-placement-current-task-name {
      color: $dark;
      font-family: 'Lato' !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
    }
  }
}

.plan-tasks-placement-list-inner-container {
  .plan-tasks-list-scroll {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 50px);
  }
  @include desktopView {
    .planner-task-container {
      margin-bottom: 5px;
      height: 50px;
    }
  }
  @include mobileView {
    .tasks-list-item.single-task-container--view-type-8 {
      padding-left: 0px;
      margin-bottom: 10px;
      
      .task-item-left {
        padding-left: 0px;
      }
    }
  }
}

.plan-tasks-placement-list-container,
.time-picker-context-action-container {
  .task-edit-overlay-header-action-button {
    .page-loader-animation {
      display: flex;
      align-items: center;
      width: 20px !important;
    }
  }
}

@include desktopView {
  .plan-tasks-placement-list-container {
    height: 100%;
    padding: 45px 30px 20px 30px;
    margin-left: 20px;
    background-color: $white;
    border-radius: 16px 0px 0px 0px;
    box-shadow: -4px 4px 10px 0px $Neutral-1;

    &--full,
    &--placing {
      .plan-tasks-placement-list-actions-container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        row-gap: 30px;
        max-width: 100px;
      }
    }
    .task-edit-overlay-header-action-button {
      display: none;
    }
  }
  .plan-tasks-placement-list-inner-container {
    &:has(.planner-empty-state) {
      position: relative;
    }
  }
  .plan-tasks-list-scroll {
    padding: 0px 10px $singleTaskHightStageTasks 0;
  }
  .plan-tasks-placement-list-container.plan-tasks-placement-list-container--desktop.plan-tasks-placement-list-container--placing {
    .plan-tasks-placement-current-task-container {
      display: none;
    }
  }
  .plan-tasks-placement-header {
    color: $purple-600;
    @include fontBody;
    line-height: 150%;
    text-align: start;
  }
  .plan-tasks-placement-list-close-button--placing,
  .plan-tasks-placement-header--placing {
    display: none;
  }

  body:has(.mini-plan-tour) {
    .plan-tasks-placement-list-container .planner-empty-state {
      display: none;
    }
  }
}

@include mobileView {
  .plan-tasks-placement-list-container {
    height: 0;
    overflow: hidden;
    padding: 25px 20px;
    border-radius: 15px 15px 0 0;
    &--mobile {
      z-index: 10;
      background-color: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .plan-tasks-placement-list-inner-container {
      padding: 0px;

      .planner-empty-state * {
        color: $Neutral-3;
      }
    }
    &--full {
      height: 100%;
      .plan-tasks-placement-header--placing,
      .plan-tasks-placement-list-close-button--placing,
      .task-edit-overlay-header-action-button {
        display: none;
      }
      .plan-tasks-placement-list-actions-container {
        gap: 20px;
      }
      .plan-tasks-placement-list-actions-container {
        margin-bottom: 20px;
      }
    }
    &--placing {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      height: $overlayMobileHeightWhenPlacingTask;
      padding-bottom: 0;
      .plan-tasks-list-scroll {
        display: none;
      }
      .plan-tasks-placement-header--full,
      .plan-tasks-placement-list-close-button--full {
        display: none !important;
      }
      .plan-tasks-placement-list-actions-container {
        gap: 15px;
      }
    }
    &--closed {
      padding: 0 !important;
    }
  }
  .plan-tasks-placement-header {
    color: $purple-500;
    @include fontBody;
  }
  .plan-tasks-placement-current-task-container {
    padding-left: 32px;
  }
  .plan-tasks-placement-list-container {
    .task-edit-overlay-header-action-button {
      .page-loader-animation {
        flex: unset !important;
      }
    }
  }
}
