@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.input-container{
  width: 100%;
  @include displayFlex(flex-start, center, column);
  
  .input-error {
    text-align: center;
    line-height: 16px;
    color: $state-error;
    white-space: nowrap;
    @include fontCaption;
  }
}

.app-input, .app-phone-input, .app-select-container {
  @include fontBody;
  padding: 8px 0 10px;
  text-align: center;
  width: 220px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid $Neutral-700;
  background-color: $white;
  color: $black;

  // Figma-Enabled
  /* Styles when the input is empty (placeholder is shown) */
  &:placeholder-shown {
    border-color: $Neutral-500;
    background-color: $Neutral-2;
  }

  // Figma-Activated
  /* Styles when the input has content */
  &:not(:placeholder-shown), :-webkit-autofill{
    // gradient border
    @include appGradientBorderForInputElement;
  }

  &::placeholder {
    color: $black;
    opacity: 0.5;
    @include fontBody;
  }

  // Figma-Focused
  &:focus, &--open {
    outline: none;
    border-color: $Neutral-700 !important;
    background-color: $white;
  }

  // Figma-Disabled
  &:disabled {
    background-color: $Neutral-4;
    border-color: transparent;

    &::placeholder{
      color: transparent;
    }
  }

  // Figma-Error
  &--error {
    border-color: $state-error !important;
    background-color: $white !important;
  }
}
