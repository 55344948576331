@import '../StageContainer.scss';

.stage-header {
  display: none;
}

@include desktopView {
  .stage-header {
    width: 100%;
    display: block;
    height: $desktopHeaderHeight;
    background-color: transparent;
    text-align: start;
    @include displayFlex(flex-start, flex-start, column);
    padding: 36px $stageContentPaddingRightDesktop 0 $stageContentPaddingLeftDesktop;
    position: relative;

    .expand,
    .minimize-stage {
      @include stageExpandAndCollapseButton;
    }

    .stage-header-content {
      width: 100%;
      padding-bottom: 13px;
      @include displayFlex(space-between, flex-end);
    }

    &--tasks {
      justify-content: space-between;

      .stage-header-content {
        align-items: center;

        .header-title {
          @include fontLargeTitle(-0.32px);
          color: $purple-600;
        }
      }
    }
  }

  .display-stage.stage-expanded .stage-header {
    align-items: center;
    
    &--plan {
      padding-left: $stagePlanContainerPaddingRightLeftOnExpendMode;
      padding-right: $stagePlanContainerPaddingRightLeftOnExpendMode;
    }

    &--tasks {
    padding-left: $stageContentPaddingLeftDesktop;
    padding-right: $stageContentPaddingLeftDesktop;

      .stage-header-content {
        max-width: $stageContentMaxWidthOnExpendMode;
      }
    }
  }

  body:has(.stage-scratchpad-container) {

    .expand img,
    .minimize-stage img {
      filter: unset;
    }
  }
}