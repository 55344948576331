@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/colors.scss';

.summaries-group {
  color: $Neutral-3;
  letter-spacing: 1px;

  .single-summary {
    border-bottom: 1px solid $Neutral-300;
    padding: 12px 0;
  }

  .single-summary {
    &:first-of-type {
      border-top: 1px solid $Neutral-300;
    }
    &:last-of-type {
      margin-bottom: 0;
      border-bottom: 1px solid $Neutral-300;
    }
  }

  .group-title {
    color: $purple-700;
    @include fontBody;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 20px;
  }
}

// mobile
@include mobileView {
  .summaries-group {
    .single-summary {
      border-top: none !important;
    }
    .single-summary:first-of-type {
      padding-top: 0 !important;
    }
  }
}
