@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

// custom style for AppOverlayPopover
.app-overlay-popover-background-container .app-overlay-popover-content-container {
  &.proactive-survey-overlay-desktop,
  &.proactive-survey-overlay-mobile {
    max-width: 462px !important;
    border-radius: 8px 8px 8px 8px !important;
    border: 1px solid $dark !important;
    box-shadow: 0px 4px 0px 0px $dark !important;
    overflow: hidden;
  }
}

.proactive-survey-popup {
  font-family: 'Dosis' !important;
  background-color: $white;
  padding: 20px;
  z-index: 1000;
  width: 100%;
  position: relative;

  p {
    color: $Neutral-3;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left;
  }

  .close-button {
    position: absolute;
    top: 7px;
    left: 7px;
    padding: 0;
    display: flex;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: $dark;
  }

  .proactive-survey-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      height: 30px;
      width: auto;
      @include textTypography(14px, 400, 0.14px);
      border: none;
      padding: 0;
      background-color: transparent;
      color: $dark;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
