@import '../../../../../../../styles/sharedStyle.index.scss';

.set-task-time-picker-btn {
  width: 50px;
  height: 30px;
  color: $dark;
  background-color: $Brand-1;
  border: 1px solid $Brand-1;
  border-radius: 5px;
  @include textTypography(14px, 400, 0.14px);

  &:disabled {
    background-color: $Neutral-1;
    border-color: $Neutral-1;
    color: $Neutral-3;
  }
}
