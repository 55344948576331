@import '../../../../../../../styles/colors.scss';

.ical-provider-sync-form {
  text-align: center;
  margin-top: 30px;

  .ical-provider-sync-form-input,
  .ical-provider-sync-form-input-container {
    border-radius: 10px;
  }

  .ical-provider-sync-form-input-container {
    width: 100%;
    height: 51px;
    border: 1px solid $Neutral-3;
    margin-bottom: 34px;
    position: relative;
    .ical-provider-sync-form-input {
      width: 100%;
      height: 100%;
      border: none;
      padding-left: 5%;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:focus {
        outline: none;
      }
    }
  }

  .ical-sync-error {
    bottom: -18px;
    height: auto;
    left: 0;
    right: 0;
    margin-inline: auto;
  }

  .ical-provider-sync-form-submit-button {
    width: 100px;
    height: 40px;
    margin: auto;
  }
}
