@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';

.login-error-page {
  .login-error-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
  }
  .login-error-title,
  .login-error-info {
    color: $dark;
  }
  .login-error-info {
    text-align: center;
    font-family: 'Lato' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  .login-error-title {
    margin-top: convertPxToVhVw(35, 'vh', 758);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
  }
}
