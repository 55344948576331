@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/colors.scss';

.proactive-reminders-container {
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.proactive-reminders-toggles-container {
  .text-and-toggle-container  {
    padding: 10px 0;
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  > *:last-child {
    margin-bottom: 40px;
  }
}

.proactive-reminders-header {
  @include textTypography(16px, 400);
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $Neutral-2;
    margin: 10px 0 20px 0;
  }
}
