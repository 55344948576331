@import '../../../styles/sharedStyle.index.scss';

.app-rich-text {
  width: 100%;
  color: $dark;

  // placeholder
  [data-slate-placeholder='true'] {
    color: inherit;
    opacity: 1 !important;
  }

  a {
    color: $blue;
  }

  &:focus,
  &:active {
    outline: none !important;
    max-height: max-content !important;
  }
}
