@import '../../../../../../../styles/sharedStyle.index.scss';

.edit-notes-modal {
  &--container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    overflow: hidden;
    padding: 20px;
    padding-bottom: 0;
    @include displayFlex(flex-start, flex-start, column);
  }

  &--actions {
    width: 100%;
    @include displayFlex(space-between, center);

    &--back {
      @include transparencyBtn;
    }

    &--clear, &--clear:disabled{
      width: 52px;
      height: 21px;
      @include fontCaption;
      color: $Neutral-3;
      border-radius: 5px;
      border: 1px solid $Neutral-2;
      background-color: transparent;
      padding: 0;
    }
  }

  &--text-box {
    flex: 1;
    margin-top: 20px;
    width: 100%;
    padding: 0 8px 30px 8px;
    resize: none;
    border: none;
    background-color: transparent;
    @include textTypography(16px, 400, 0.16px, 18px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::placeholder {
      font-family: 'Dosis' !important;
      color: $dark;
    }

    &:focus {
      outline: none;
      background: transparent;
    }
  }
}
