@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.login-container {
  width: 100vw;
  height: 100%;
  text-align: center;

  &.auth-screen:has(.page-loader-animation) {
    padding-top: 0;
    @include displayFlex(center, center);
  }

  .flex-column {
    @include displayFlex(space-between, center, column);
    width: 100%;
    height: 100%;
  }

  .login-redirect-user-container {
    text-align: center;
    margin-bottom: 16px;
    .login-redirect-user-text {
      @include fontTitle2;
      line-height: 22px;
      color: $dark;
      &--link {
        text-decoration: underline;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        cursor: pointer;
      }
    }
  }

  .login-error {
    display: block;
    @include fontFootnote;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 10px;
  }

  .terms-of-service-container{
    max-width: 240px;
    .terms-of-service-content, *{
      color: $dark;
      line-height: 16px;
      @include fontCaption;
    }
  }

  .auth-hero-card {
    margin-bottom: 0;
  }
}

.instructions-modal-overlay {
  width: 90%;
  background-color: $white;
}

// small mobile devices
@media only screen and (max-width: 768px) and (max-height: 660px) {
  .login-container {
    .login-redirect-user-container {
      .login-redirect-user-text {
        @include fontFootnote;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .login-container {
    .login-redirect-user-container {
      margin-bottom: 32px;
    }
  }
}

@media only screen and (min-width: 769px) and (min-height: 660px){
  .login-container{
    .flex-column {
      justify-content: flex-start;

      .login-options {
        margin-top:4vh;
      }
    }
  }

  @media only screen and (max-height: 700px){
    .login-container .flex-column{
      .login-options {
        margin-top: 0;
      }
    }
  }
}

// small mobile/desktop height < 700
@media only screen and (max-height: 700px) {
  .login-container {
    .login-redirect-user-container {
      .login-redirect-user-text {
        line-height: normal;
        display: inline;
        &--link {
          margin-left: 3px;
        }
      }
    }
  }
}