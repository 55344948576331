@import '../../../../../../styles/sharedStyle.index.scss';

.task-edit-overlay-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-and-back-section {
  @include displayFlex(flex-start, center);
}

.task-edit-overlay-header-back {
  @include transparencyBtn;
  margin-right: 8px;
}

.task-edit-overlay-header-text {
  color: $Neutral-3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.task-edit-overlay-header-actions-container {
  display: flex;
  gap: 8px;
}

.task-edit-overlay-header-action-button {
  height: 30px;
  @include fontBody;
  @include displayFlex(center, center);
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;

  &--clear {
    width: 62px;
    color: $black;
    opacity: 0.5;
    border: 1px solid $Neutral-2;
    border: none;
    background: transparent;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &--set {
    width: 80px;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.task-edit-overlay-header-container {
  margin-bottom: 15px;
}

.task-edit-overlay-container {
  &:has(.task-edit-overlay-date-picker) {
    .task-edit-overlay-header-container {
      margin-bottom: 26px;
    }
  }

  &:has(.quick-option-reminder-workTime),
  &:has(.quick-options-recurrence) {
    .task-edit-overlay-header-container {
      margin-bottom: 45px;
    }
    .task-edit-overlay-header-action-button {
      display: none;
    }
  }

  &:has(.task-edit-overlay-duration-custom-time-picker-container) {
    .task-edit-overlay-header-container {
      margin-bottom: 20px;
    }
  }
}
