@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.confirm-modal-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: transparent;
  opacity: 0;
  animation: fadeInOpacity7 0.5s forwards;
  -webkit-animation: fadeInOpacity7 0.5s forwards;
}

.confirm-modal {
  z-index: 999;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 349px;
  min-height: 230px;
  border-radius: 8px;
  background: $white;
  padding: 28px 30px 30px 30px;
  z-index: 99999;
  @include boxShadow();
  @include displayFlex(space-between, flex-start, column);

  &-content {
    width: 100%;
  }

  h4.confirm-modal-title {
    @include fontLargeTitle(-0.32px, normal);
    margin-bottom: 36px;
    margin-left: 10px;
    text-align: start;
    white-space: pre-line;
    color: $purple-600;
  }

  p.confirm-modal-info {
    @include fontFootnote;
    line-height: 18px;
    margin-bottom: 20px;
    margin-left: 10px;
    white-space: pre-line;
  }

  .confirm-modal-footer {
    width: 100%;
    @include displayFlex(flex-end, center);

    button {
      height: 37px;
      width: 100px;
      @include fontFootnote;
      line-height: 18px;
      padding: 0;
      color: $white;

      &.confirm-modal-cancel {
        background-color: transparent;
        text-decoration: underline;
        color: $appDarkColor;
      }

      &.confirm-modal-confirm {
        margin-left: 5px;
      }
    }

    &:not(:has(button.confirm-modal-cancel)),
    &:not(:has(button.confirm-modal-confirm)) {
      justify-content: center;
    }
  }
}

@keyframes fadeInOpacity7 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

#app-overlay-popover-container .app-overlay-popover-content-container {
  .confirm-modal-background {
    display: none;
  }

  .confirm-modal {
    border: none;
    box-shadow: none;
    transform: unset;
    position: unset;
  }
}
