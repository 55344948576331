@import '../../../../../../styles/sharedStyle.index.scss';

.clear-scratchpad {
  height: 30px;
  min-height: 30px;
  border-radius: 5px;
  border: 1px solid $white;
  background: transparent;
  padding: 0;
  padding-bottom: 1px;
  @include textTypography(16px, 500, 0.16px);
}

@include mobileView {
  .clear-scratchpad {
    width: 52px;
    min-width: 52px;
    display: none;
    color: $white;
  }

  body {
    &:has(.display-stage .chat .stage-scratchpad-container) {
      .clear-scratchpad {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .clear-scratchpad {
        display: none !important;
      }
    }
  }
}

@include desktopView {
  .clear-scratchpad {
    width: 76px;
    min-width: 76px;
    border-color: $Neutral-3;
    color: $Neutral-3;
    margin-left: 35px;
  }
}
