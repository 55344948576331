@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

.calendar-day-container {
  height: auto;
  overflow: hidden;

  .calendar-events-container {
    position: relative;
    .calendar-hour-line {
      position: absolute;
      right: 0;
      width: 100%;
      border-top: 1px solid $purple-200;
      user-select: none; /* Prevents text selection while long press event triggered in mobile */

      &--half {
        border: transparent;
      }

      &--transparent {
        border-top: transparent;
      }

      &.draggable-item-is-over {
        border-top: 1px solid $white;
      }

      &--half.draggable-item-is-over,
      &--transparent--half.draggable-item-is-over {
        border-top: 1px dashed $white;
      }

      .calendar-hour-text {
        position: absolute;
        top: -8px;
        left: 0px;
        font-size: 10px;
        color: $appDarkColor;
        background-color: $white;
        font-weight: 700;
        text-align: left;
        width: 20px;
      }
    }

    &:has(.calendar-event.resizing) {
      .calendar-hour-line {
        cursor: ns-resize;
      }
    }
  }
}
