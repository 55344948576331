@import '../../../../../../../styles/sharedStyle.index.scss';

.task-tags-container {
  .tags-list {
    width: 100%;
    @include displayFlex(flex-start, center);
    flex-wrap: wrap;
    row-gap: 10px;

    button.add-tag {
      @include transparencyBtn;
      @include displayFlex(center, center);
      height: 25px;
      border-radius: 5px;
      border: none;
      color: $taskDetailsPlaceholderColor;
    }
  }

  &--read-only {
    &:not(:has(.single-tag)) {
      margin: 0 !important;
    }

    .single-tag button.delete-tag {
      display: none !important;
    }
  }
}

.add-edit-task-container form.add-edit-task-form .single-tag,
.single-tag {
  width: fit-content;
  @include displayFlex(space-between, center);
  margin-right: 10px;
  padding: 0px 12px;
  border-radius: 8px;
  border: 1px solid $Neutral-1;
  color: $white;
  height: 25px;
  min-width: 60px;
  @include fontCaption;

  &.without-name {
    justify-content: flex-end;
  }

  button.delete-tag {
    margin-left: 5px;
    @include transparencyBtn;
  }
}
