@import '../../../../../../../styles/colors.scss';
@import '../../../../../../../styles/mixin.scss';
@import '../../../../../../../styles/layout.scss';

$checkboxDefaultColor: $firstTaskTagDefaultColor;
$checkboxSize: 18px;

.tasks-list-item {
  width: 100%;
  height: $singleTaskHightStageTasks;
  @include displayFlex(space-between, center);
  padding: 0px;
  background-color: transparent;
  margin-bottom: 0px;
  border-bottom: 1px solid $taskBorderBottomColor;
  position: relative;
  cursor: pointer;

  .task-item-left {
    @include displayFlex(flex-start, center);
    padding-left: 0px;
    flex: 1;
    max-width: 80%;
    
    label.task-complete-label {
      padding-left: calc($checkboxSize + 5px);
      position: relative;
      @include displayFlex(flex-start, center);
      width: 18px;
      height: 18px;
      min-height: 24px;
      // var(--task-checkbox-color): Define a CSS variable to set the checkbox color outside this file on the .tsx file
      @include appCheckboxUsingBeforeAndAfter(var(--task-checkbox-color, $checkboxDefaultColor), $checkboxSize);
    }

    .task-name-container {
      @include displayFlex(flex-start, center);
      max-width: 90%;

      span.task-name, span.external-event-source {
        display: block;
        margin-left: 12px;
        margin-right: 8px;
        color: $appDarkColor;
        @include fontBody;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: start;

        img {
          width: 10px;
          height: 10px;
          margin-right: 3px;
          margin-bottom: -1px;
        }
      }
    }
  }

  .task-item-right {
    @include displayFlex(flex-end, center);

    .task-actions-container {
      @include displayFlex(flex-end, center);
    }

    button.priority {
      @include transparencyBtn;
      margin-left: 10px;

      .single-task-priority-icon {
        width: 20px;
        height: 20px;

        path {
          transition: all 0.3s ease-in-out;
        }

        &--on path {
          fill: $appDarkColor;
        }

        &--off path {
          fill: $purple-200;
        }
      }
    }

    .task-icons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      margin-top: 2px;

      >*:not(:last-child) {
        margin-right: 6px;
      }

      &:not(:has(*)) {
        height: 10px;
      }

      .due-date-tag {
        min-width: 40px;
        height: 16px;
        padding: 0 8px 1px;
        @include displayFlex(center, center);
        color: $white;
        background-color: $appDarkColor;
        border-radius: 20px;
        @include fontCaption(700);
        white-space: nowrap;

        &--overdue {
          background-color: $state-error;
          color:$appDarkColor;
          padding-bottom: 0px;
          padding-top: 1px;
        }
      }

      .task-siblings-info {
        color: $appDarkColor;
        @include fontBody();
      }
    }
  }

  &:has(input.complete-checkbox[type='checkbox']:checked),
  &.completed-task {
    .task-item-left span.task-name {
      text-decoration: line-through;
    }
  }

  &--event {
    .task-item-left {
      padding-left: 18px;
      .task-name-container span.task-name {
        margin-left: 14px;
      }
    }
  }
}

@include desktopView {
  .tasks-list-item {
    .task-item-left {
      label.task-complete-label {

        // accessibility - add outline when focusing with keyboard tabbing only
        &:has(input[type='checkbox']:focus) {
          &::before {
            outline: solid $black;
          }
        }

        // accessibility
        &:has(input[type='checkbox']:focus:not(:focus-visible)) {
          &::before {
            outline: none;
          }
        }
      }

      span.task-name {
        margin-right: 8px;
      }
    }

    .task-item-right {
      position: relative;

      .task-actions-container {
        position: absolute;
        right: 15px;
      }

      .task-action-button {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      button.priority {
        margin-left: 10px;
      }
    }

    &:hover {
      .task-item-right .task-action-button {
        opacity: 1;
      }

      &:has(.task-actions-container) {
        .task-icons-container {
          visibility: hidden;
        }
      }
    }
  }
}

// hide desktop tasks in mobile and tablets
@mixin hideDesktopTasks {
  .tasks-list-item {
    &.single-task-desktop-view,
    .task-actions-container {
      display: none !important;
    }
    &:hover {
      .task-icons-container {
        visibility: visible !important;
      }
    }
  }
}

@include mobileView {
  @include hideDesktopTasks;
  .tasks-list-item {
    .task-item-left {
      max-width: 75%;
    }
  }
}

@include TabletsAndIPadsView {
  @include hideDesktopTasks;
}

.single-task-container-mobile {
  @include hideDesktopTasks;
}

body:has(.dislike-feedback-container) {
  .tasks-list-item ,.swipeable-item-container .swipeable-item{
    position: unset !important;
  }
}
