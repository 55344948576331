@import '../../../../../../styles/sharedStyle.index.scss';
@import '../../stage-tasks/create-or-edit-task/subtasks-section/SubtasksSection.scss';

.scratchpad-task-list-container {
  width: 100%;

  .scratchpad-task-list {
    width: 100%;

    .tasks-list-item {
      border: none;
      border-radius: 0;
    }

    .scratchpad-notification.fadeIn {
      animation-duration: 0.2s !important;
    }
  }

  input.add-scratchpad-task {
    height: 50px;
    border-radius: 5px;
    color: $dark;
    font-weight: 400;
    padding: 0 10px;

    &::placeholder {
      color: $Neutral-3;
      font-weight: 500;
    }
  }

  .update-task-notification {
    margin-bottom: 10px;
  }
}

@include mobileView {
  .scratchpad-task-list-container {
    .scratchpad-task-list {
      .swipeable-task-item {
        margin: 0;
        border-bottom: 1px solid $Neutral-1;

        .task-action-button {
          border: none;

          &--due {
            margin-right: 1px;
          }
        }

        .tasks-list-item {
          box-shadow: none;
        }
      }
    }
  }
}

@include desktopView {
  .scratchpad-task-list-container {
    .scratchpad-task-list {
      .tasks-list-item {
        box-shadow: none;
        border-bottom: 1px solid $Neutral-1;
        margin: 0;
        padding-right: 0;

        .task-actions-container {
          right: 0;

          .task-action-button {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
