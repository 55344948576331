@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.instructions-modal-overlay {
  max-width: 350px !important;
  height: 200px !important;
  border-radius: 8px 8px 8px 8px !important;
  border: 1px solid $black !important;
  box-shadow: 0px 4px 0px 0px $black !important;
  overflow: hidden;
}

.instructions-modal {
  font-family: 'Lato' !important;
  @include textTypography(16px, 400, 0.16px);
  white-space: pre-line;
  padding: 45px;
  z-index: $appOverlayComponentZIndex;
  width: 100%;
  position: relative;

  p {
    color: $black;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 12px;
    left: 5px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: $dark;
  }
}
