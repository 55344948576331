@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.card-wrapper {
  width: 100%;
  height: max-content;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 4px 0px 0px $Neutral-1;
  border: 1px solid $Neutral-2;
  text-align: start;
  overflow: hidden;
  margin: 15px auto;

  .card-header {
    width: 100%;
    height: 20px;
    background-color: $Brand-1;
    padding-right: 11px;
    @include displayFlex(flex-end, center);

    img {
      filter: invert(1);
      cursor: pointer;
      display: none;
    }
  }
}

@media only screen and (min-width: 769px) {
  .card-wrapper {
    .card-header {
      height: 25px;
    }
  }
}
