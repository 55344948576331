@import '../../../../styles/sharedStyle.index.scss';
@import '../CreatePlusButton.module.scss';


.planner-plus-overlay {
  @include displayFlex(center, center, column);

  .plus-overlay-single-option {
    width: 189px;
    height: 55px;
    background-color: $planScheduledItemBackground;
    border-radius: 8px;
    padding: 0 18px;
    margin: 0 auto;
    @include displayFlex(space-between, center);
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .single-option-title {
      @include fontBody;
      color: $appDarkColor;
    }
  }
}

@include mobileView {
  .planner-plus-overlay {
    padding-top: 50px;
    padding-bottom: 50px;
    user-select: none;
  }
}

@include desktopView {
  .app-overlay-popover-background-container .app-overlay-popover-content-container.plan-plus-overlay-menu-container {
    width: auto;
  }
  .planner-plus-overlay {
    width: calc($planPlusMenuWidth * 1px);
    height: calc($planPlusMenuHeight * 1px);
  }
}