@import '../../../../styles/sharedStyle.index.scss';

$workBlockContainerBackground: $planScheduledItemBackground;

.plan-day-scheduled-work-block {
  width: 100%;
  min-height: 90px;
  background-color: $workBlockContainerBackground;
  cursor: pointer;
  margin: 10px 0;
  padding: 16px 4px;
  border-radius: 5px;

  &--header {
    @include displayFlex(space-between, center);
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 9px;

    .workBlock-title {
      @include fontFootnote;
      color: $appDarkColor;

      .work-block-icon {
        margin-right: 12px;
      }
    }

    .pen-and-time-range {
      @include displayFlex(flex-end, center);
      column-gap: 11px;

      .work-block-time-range {
        @include textTypography(14px, 400, 0.14px);
      }

      .edit-work-block-pen {
        @include transparencyBtn();

        svg path {
          stroke: $Neutral-3;
        }
      }
    }
  }

  &--tasks {
    @include displayFlex(flex-start, flex-start, column);
    row-gap: 16px;
    margin-top: 16px;
    width: 100%;

    .single-task-container {
      margin-bottom: 0px !important;
      .tasks-list-item {
        background-color: $workBlockContainerBackground;
        height: 24px !important;
        .task-item-left{
          .quick-win-icon-container {
            display: none;
          }
        }
        .task-item-right {
          .task-icons-container {
            display: none !important;
          }
        }
      }
    }
  }
}