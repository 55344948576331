@import '../../../styles/sharedStyle.index.scss';

.app-empty-state {
  @include displayFlex(center, center);
  width: 100%;
  gap: 18px;
  position: absolute;

  &--paragraph {
    @include fontTitle2;
    color: $appDarkColor;
  }
}

@include mobileView {
  .app-empty-state {
    flex-direction: column-reverse;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 30px;
    gap: 25px;

    &--paragraph {
      text-align: center;
      white-space: pre-line;
    }
  }
}

@include desktopView {
  .app-empty-state {
    left: 0;
    bottom: 47px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 30px 0 40px;

    &--paragraph {
      height: 70px;
      @include displayFlex(center, flex-end);
    }
  }
}
