@import '../../../../../../styles/mixin.scss';
@import '../../../../../../styles/colors.scss';

.single-summary {
  cursor: pointer;

  .summary-time {
    @include fontCaption;
    width: 50px;
    line-height: 16px;
    padding: 0 5px;
    height: 16px;
    background-color: $Neutral-300;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $appDarkColor;
    margin-bottom: 10px
  }

  .summary-name {
    @include fontBody;
    color: $appDarkColor;
  }

  .summary-not-available {
    @include fontBody;
    color: $purple-400;
  }
}