@import '../../../styles/colors.scss';

.my-plan-navigation {
  text-align: end;
  position: relative;
  width: fit-content;
  float: right;
  
  svg.my-plan-navigation-arrows-icon * {
    stroke: $purple-600;
  }
}

.my-plan-navigation-button {
  position: absolute;
  top: 1px;
  width: 15px;
  height: 14px;
  cursor: pointer;
  background: transparent;
}

.my-plan-navigation-button:first-of-type {
  right: -3px;
}

.my-plan-navigation-button:nth-of-type(2) {
  right: 17px;
}
