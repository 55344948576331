@import '../../../../../../../../styles/sharedStyle.index.scss';

.quick-options-recurrence {
  .task-overlay-flex-options-container {
    gap: 5px;
    margin-bottom: 60px;

    .task-overlay-quick-options-single-option {
      width: auto;
      padding: 1px 12px;
      height: 32px;
      border-radius: 12px;
      background: $Neutral-1;
      color: $appDarkColor;

      h2 {
        @include fontCaption;
        margin: 0;
      }
    }
  }
}
